/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, Dispatch, PayloadAction } from '@reduxjs/toolkit';

const localStorageMiddleware =
  (store: { getState: any; dispatch: Dispatch }) =>
  (next: (action: Action) => void) =>
  (action: PayloadAction) => {
    if (action.type.split('/')[0] === 'Basket') {
    }
    next(action);
  };

export default localStorageMiddleware;
