import { useEffect } from 'react';
import Hooks from '../../hooks';
import { getLoaderStatus } from '../../modules/layout/selectors';
import Spinner from '../Spinner/Spinner';
import './Loader.scss';

const Loader = () => {
  const show = Hooks.useAppSelector((s) => getLoaderStatus(s.layout));

  useEffect(() => {
    console.log(show);
  }, [show]);

  return (
    <>
      {show ? (
        <div className="berg-components-loader">
          <Spinner></Spinner>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
