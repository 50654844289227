import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../services/axios';
import actionTypes from '../actionTypes';
import {
  IAnnouncementsResponse,
  IAnnouncementsResponseState,
} from './announcements.model';

export const fetchAnnouncements = createAsyncThunk(
  actionTypes.FETCH_ANNOUNCEMENTS,
  async (): Promise<IAnnouncementsResponseState> => {
    try {
      const response = await axiosInstance.get<IAnnouncementsResponse[]>(
        '/v1/location/announcements'
      );

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      throw e;
    }
  }
);
