import { useTranslation } from 'react-i18next';
import Icons from '../../assets/icons';
import './ContactForm.scss';
import getFormData from '../../utils/getFormData';
import siteSettings from '../../config/siteSettings';
import { sendContactForm } from '../../modules/location/contact/operations';
import { IContactForm } from '../../modules/location/details/models/contact';
import TextArea from './TextArea';
import { useState } from 'react';

const ContactForm = () => {
  const { t } = useTranslation('components');

  const [shouldReset, setShouldReset] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const values = getFormData(event) as unknown as IContactForm;

    sendContactForm(values).then((status) => {
      if (status == 200) {
        console.log('success');
        setShouldReset(true);
      } else {
        console.log('fail');
      }
    });

    setShouldReset(false);
  };

  const facebook = siteSettings.facebookUrl;
  const instagram = siteSettings.instagramUrl;

  return (
    <div key={`${shouldReset}`} className="berg-components-contact-form">
      <div className="contact-form-content">
        <h6>{t('contact_form.header')}</h6>
        <form onSubmit={handleSubmit}>
          <div className="contact-form-input">
            <label htmlFor="contact-name">{t('contact_form.name')}</label>
            <input
              type="text"
              id="contact-name"
              name="name"
              placeholder={t('contact_form.name_placeholder')}
              required
              maxLength={256}
            />
          </div>
          <div className="contact-form-input">
            <label htmlFor="contact-email">{t('contact_form.email')}</label>
            <input
              type="email"
              name="mail"
              id="contact-email"
              placeholder={t('contact_form.email_placeholder')}
              required
              maxLength={50}
            />
          </div>
          <TextArea
            label={t('contact_form.message')}
            name="message"
            placeholder={t('contact_form.message_placeholder')}
            required={true}
          />
          <button className="contact-form-submit-btn">
            {t('contact_form.submit')}
          </button>
          <div className="contact-icons-wrapper">
            {instagram && (
              <a href={instagram}>
                <Icons.Ig />
              </a>
            )}
            {facebook && (
              <a href={facebook}>
                <Icons.Fb />
              </a>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
