import { useEffect, useRef, useState } from 'react';
import './OptionsSwitch.scss';

type TValue = string | number | boolean;

interface IOption {
  label: string;
  value: TValue;
  disabled?: boolean;
}

interface IProps {
  option1: IOption;
  option2: IOption;
  selected?: TValue;
  onChange?: (value: TValue) => void;
}

const OptionsSwitch = (props: IProps) => {
  const { option1, option2, selected, onChange } = props;

  const defaultOption =
    [option1, option2].find((option) => option.value === selected) ?? option1;

  const [chosenOption, setChosenOption] = useState(defaultOption.value);
  const [indicatorStyle, setIndicatorStyle] = useState({});

  const option1Ref = useRef<HTMLDivElement>(null);
  const option2Ref = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChosenOption(defaultOption.value);
  }, [defaultOption.value]);

  useEffect(() => {
    const timeoutIndex = setTimeout(() => {
      indicatorRef.current?.classList.add(
        'berg-components-options-switch-indicator'
      );
    }, 1);

    return () => {
      clearTimeout(timeoutIndex);
    };
  }, []);

  useEffect(() => {
    switch (chosenOption) {
      case option1.value:
        setIndicatorStyle({
          width: option1Ref.current?.clientWidth,
        });
        break;

      case option2.value:
        setIndicatorStyle({
          transform: `translateX(${option1Ref.current?.clientWidth}px)`,
          width: option2Ref.current?.clientWidth,
        });
        break;
    }
  }, [chosenOption, option1.value, option2.value]);

  const onOptionChoose = (option: IOption) => {
    if (option.disabled) {
      return;
    }

    setChosenOption(option.value);
    if (onChange) {
      onChange(option.value);
    }
  };

  const getOptionStyle = (value: TValue) => ({
    color: chosenOption === value ? 'var(--white)' : '',
  });

  return (
    <div className="berg-components-options-switch">
      <div style={indicatorStyle} ref={indicatorRef} />
      <div
        ref={option1Ref}
        style={getOptionStyle(option1.value)}
        className="berg-components-options-switch-option"
        aria-disabled={option1.disabled}
        onClick={() => onOptionChoose(option1)}
      >
        {option1.label}
      </div>
      <div
        ref={option2Ref}
        style={getOptionStyle(option2.value)}
        className="berg-components-options-switch-option"
        aria-disabled={option2.disabled}
        onClick={() => onOptionChoose(option2)}
      >
        {option2.label}
      </div>
    </div>
  );
};

export default OptionsSwitch;
