import './ThanksForOrder.scss';

import { Trans } from 'react-i18next';
import i18n from '../../i18n';
import Pictures from '../../assets/picture';

const ThanksForOrder = () => (
  <section className="berg-components-thanks">
    <img
      className="berg-components-thanks__image"
      src={Pictures.DlugaPolana}
      alt={i18n.t('components:thanks_for_order.alt_image')}
    />
    <Trans
      ns="components"
      i18nKey="thanks_for_order.description"
      components={{
        br: <br />,
        strong: <strong />,
        p: <p />,
      }}
    ></Trans>
  </section>
);

export default ThanksForOrder;
