import Components from '..';
import { ITicket } from './types';
import './PassesAndTickets.scss';

interface IProps {
  tickets: ITicket[];
}

const PassesAndTickets = (props: IProps) => {
  const { tickets } = props;

  return (
    <div className="berg-components-passes-and-tickets">
      {tickets?.map((ticket) => (
        <Components.Ticket key={ticket.id} ticket={ticket} />
      ))}
    </div>
  );
};

export default PassesAndTickets;
