import Icons from '../../assets/icons';
import './Tooltip.scss';

interface IProps {
  content?: string | null;
  children?: JSX.Element;
}

const Tooltip = (props: IProps) => {
  const { content, children } = props;

  if (['null', 'undefined'].includes(typeof content) || content === '') {
    return children ?? null;
  }

  return (
    <div className="berg-components-tooltip">
      <div className="t-top" data-tooltip={content}>
        {children ?? <Icons.InfoCircle />}
      </div>
    </div>
  );
};

export default Tooltip;
