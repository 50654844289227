import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./StickyCTA.scss";

const StickyCTA = () => {
  const { t } = useTranslation("components");

  return (
    <Link to="/plan-stay" className="berg-components-sticky-cta">
      {t("sticky_cta")}
    </Link>
  );
};

export default StickyCTA;
