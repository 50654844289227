import React, { useEffect, useState } from 'react';
import Hooks from '../../../hooks';
import Components from '../../../components';

interface IProps {
  icon: any;
  label: string;
  name: 'adults' | 'children';
  tooltip?: string;
  invalid?: boolean;
  onChange?: (event: {
    name: string;
    value: number;
    childrenAges?: number[];
  }) => void;
  value?: number;
  childrenAges?: number[];
}

const NumberField = (props: IProps) => {
  const { icon, label, name, tooltip, invalid, onChange, value, childrenAges } =
    props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [count, setCount] = useState(value ?? 0);

  const triggerDropdown = () => setIsExpanded((prev) => !prev);

  const onSave = (value: number) => {
    setCount(value);
    if (onChange) {
      onChange({ name, value });
    }
    triggerDropdown();
  };

  const onSaveChildrenAges = (value: number, childrenAges: number[]) => {
    setCount(value);
    if (onChange) {
      onChange({ name, value: value, childrenAges });
    }
    triggerDropdown();
  };

  const dropdownRef = Hooks.useOutsideClick({
    shouldWatch: isExpanded,
    callback: triggerDropdown,
  });

  const labelClassName = `search-number-field__label${
    count > 0 ? ' search-number-field__label--active' : ''
  }`;
  useEffect(() => {
    if (value) {
      setCount(value);
    }
  }, [value]);
  return (
    <div
      className="search-number-field"
      ref={dropdownRef}
      aria-invalid={invalid}
    >
      <div className="search-number-field__icon">
        <Components.Tooltip content={tooltip} />
        {icon}
      </div>
      <button
        className="search-number-field__caret"
        type="button"
        onClick={triggerDropdown}
      >
        <label htmlFor={name} className={labelClassName}>
          <span>{label}: </span>
          <span>{count}</span>
        </label>
        <div
          className="search-number-field__arrow"
          aria-expanded={isExpanded}
          role="switch"
        />
      </button>
      <div
        className="search-number-field__dropdown-list"
        aria-expanded={isExpanded}
      >
        <Components.ChoiceNumberChildren
          isExpanded={isExpanded}
          name={name}
          count={count}
          childrenAges={childrenAges?.map((x) => x.toString())}
          onSave={onSaveChildrenAges}
        />
        <Components.ChoiceNumberPersons
          isExpanded={isExpanded}
          count={count}
          onSave={onSave}
          name={name}
        />
      </div>
    </div>
  );
};

export default NumberField;
