import BergStack from '../BergStack/BergStack';
import './BergTextInput.scss';
interface IBergTextInputProps extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  id: string;
  error?: string;
}

const BergTextInput = ({
  onChange,
  value,
  label,
  id,
  error,
  required,
  children,
  ...props
}: IBergTextInputProps) => (
  <div className="berg-text-input" aria-required={required}>
    <label htmlFor={id}>{label}</label>
    {children}
    <BergStack direction="column" style={{ width: '100%' }}>
      <input
        id={id}
        type="text"
        value={value}
        onChange={onChange}
        required={required}
        {...props}
      />
      {error ? (
        <div className="berg-text-input__error-message">{error}</div>
      ) : null}
    </BergStack>
  </div>
);

export default BergTextInput;
