import { Season } from '../../../config/globalVariables';
import { IDefaults, ISettingsModel } from './settings.model';

export const getTheme = (state: ISettingsModel): Season => state.data?.theme;

export const getLoaderStatus = (state: ISettingsModel): boolean =>
  state.loading;

export const getInvoiceOccurrence = (state: ISettingsModel): boolean =>
  state.data.invoiceDuringOrder;

export const getSkiPassDefaultTypeId = (state: ISettingsModel): string =>
  state.data.skiPass?.defaultTypeId;

export const getAquaparkDefaults = (state: ISettingsModel): IDefaults =>
  state.data?.aquapark;

export const getSkiPassDefaults = (state: ISettingsModel): IDefaults =>
  state.data?.skiPass;

export const getParkingDefaults = (state: ISettingsModel): IDefaults =>
  state.data?.parking;
