import React, { Children } from 'react';
import Icons from '../../assets/icons';
import './ArrowsCarousel.scss';

export interface ICarouselProps {
  children: React.ReactNode;
}

const Carousel = ({ children }: ICarouselProps): JSX.Element => {
  /**
   * Total item
   */
  const length = React.useMemo(() => Children.count(children), [children]);

  /**
   * Current Index Item of the Carousel
   */
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  /**
   * First touch position to be used in calculation for the swipe speed
   */
  const [touchPosition, setTouchPosition] = React.useState<null | number>(null);

  /**
   * Move forward to the next item
   */
  const nextItem = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  /**
   * Move backward to the previous item
   */
  const previousItem = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  /**
   * Handle when the user start the swipe gesture
   * @param e TouchEvent
   */
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    // Save the first position of the touch
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  /**
   * Handle when the user move the finger in swipe gesture
   * @param e TouchEvent
   */
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    // Get initial location
    const touchDown = touchPosition;

    // Proceed only if the initial position is not null
    if (touchDown === null) {
      return;
    }

    // Get current position
    const currentTouch = e.touches[0].clientX;

    // Get the difference between previous and current position
    const diff = touchDown - currentTouch;

    // Go to next item
    if (diff > 5) {
      nextItem();
    }

    // Go to previous item
    if (diff < -5) {
      previousItem();
    }

    // Reset initial touch position
    setTouchPosition(null);
  };

  return (
    <div className="berg-components-arrows-carousel-container">
      <div className="berg-components-arrows-carousel-wrapper">
        {currentIndex > 0 ? (
          <button onClick={previousItem} className="carousel-left-arrow-button">
            <Icons.ArrowRight className="carousel-left-arrow" />
          </button>
        ) : null}
        <div
          className="berg-components-arrows-carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className="berg-components-arrows-carousel-content"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {children}
          </div>
        </div>
        {currentIndex < length - 1 ? (
          <button onClick={nextItem} className="carousel-right-arrow-button">
            <Icons.ArrowRight />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Carousel;
