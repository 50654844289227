import { removeBasketItem } from '../../../modules/basket/slice';
import Hooks from '../../../hooks';
import Icons from '../../../assets/icons';
interface ICartOperationsProps {
  basketId: string;
}
const CartOperations = ({ basketId }: ICartOperationsProps) => {
  const dispatch = Hooks.useAppDispatch();
  const handleDeleteClick = () => {
    dispatch(removeBasketItem(basketId));
  };
  return (
    <a onClick={handleDeleteClick}>
      <Icons.Delete />
    </a>
  );
};

export default CartOperations;
