import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../services/axios';
import actionTypes from '../actionTypes';

import { ISettingsResponse, ISettingsResponseState } from './settings.model';
import { hideLoader, showLoader } from './slice';

export const fetchSettings = createAsyncThunk(
  actionTypes.FETCH_SETTINGS,
  async (_, thunkApi): Promise<ISettingsResponseState> => {
    thunkApi.dispatch(showLoader());

    try {
      const response = await axiosInstance.get<ISettingsResponse>(
        '/v1/location/settings'
      );

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      throw e;
    } finally {
      thunkApi.dispatch(hideLoader());
    }
  }
);
