import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../services/axios';
import actionTypes from '../actionTypes';

import { IWeatherResponse, IWeatherResponseState } from './weather.model';

export const fetchWeather = createAsyncThunk(
  actionTypes.FETCH_WEATHER,
  async (): Promise<IWeatherResponseState> => {
    try {
      const response = await axiosInstance.get<IWeatherResponse>(
        '/v1/location/weather'
      );

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      throw e;
    }
  }
);
