import { Moment } from 'moment';
import { useContext } from 'react';
import { DaysContext } from './SingleCalendar';
import isWithinDatesRange from '../../utils/isWithinDatesRange';

/* eslint-disable complexity */
const Day = ({ date }: { date: Moment }) => {
  const {
    selectedMonth,
    dateChoice,
    firstRangeStart,
    firstRangeEnd,
    secondRangeStart,
    secondRangeEnd,
    onClick,
    today,
  } = useContext(DaysContext);

  const className = [];

  const isDisabled = date.isBefore(today, 'day');

  if (today?.isSame(date, 'day')) {
    className.push('current');
  }

  const isWithinFirstRange =
    !!firstRangeStart &&
    !!firstRangeEnd &&
    isWithinDatesRange(firstRangeStart, firstRangeEnd, date);
  const isWithinSecondRange =
    !!secondRangeStart &&
    !!secondRangeEnd &&
    isWithinDatesRange(secondRangeStart, secondRangeEnd, date);

  if (isWithinFirstRange) {
    className.push('between between--first');
  }

  if (isWithinSecondRange && !isWithinFirstRange) {
    className.push('between between--second');
  }

  if (!date.isSame(selectedMonth, 'month')) {
    className.push('muted');
  }

  if (date.isSame(dateChoice, 'day')) {
    className.push('active');
  }

  if (isDisabled) {
    className.push('disabled');
  }

  return (
    <span
      onClick={isDisabled ? undefined : () => onClick?.(date.toISOString())}
      data-current-date={date}
      className={className.join(' ')}
    >
      {date.date()}
    </span>
  );
};

export default Day;
