import {
  createContext,
  MouseEventHandler,
  ReactNode,
  useRef,
  useState,
} from 'react';

interface IProps {
  children: ReactNode;
}

interface INavigationContext {
  navRef: React.RefObject<HTMLUListElement>;
  searchRef: React.RefObject<HTMLFormElement>;
  isMobileMenuVisible: boolean;
  isMobileSearchVisible: boolean;
  toggleMobileMenuVisibility: () => void;
  toggleMobileSearchVisibility: MouseEventHandler<HTMLButtonElement>;
}

export const NavigationContext = createContext<Partial<INavigationContext>>({});

const Navigation = (props: IProps) => {
  const { children } = props;

  const navRef = useRef<HTMLUListElement>(null);
  const searchRef = useRef<HTMLFormElement>(null);

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);

  const toggleMobileMenuVisibility = () => {
    setIsMobileMenuVisible((prev) => !prev);

    if (isMobileSearchVisible) {
      toggleMobileSearchVisibility();
    }

    navRef.current?.classList.toggle('responsive-nav');
    document.body.classList.toggle('no-scroll');
  };

  const toggleMobileSearchVisibility = () => {
    setIsMobileSearchVisible((prev) => !prev);

    if (isMobileMenuVisible) {
      toggleMobileMenuVisibility();
    }

    searchRef.current?.classList.toggle('berg-components-search--mobile');
    document.body.classList.toggle('no-scroll');
  };

  return (
    <NavigationContext.Provider
      value={{
        navRef,
        searchRef,
        isMobileMenuVisible,
        isMobileSearchVisible,
        toggleMobileMenuVisibility,
        toggleMobileSearchVisibility,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default Navigation;
