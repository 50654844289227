import React from 'react';
import Hooks from '../../../hooks';
import { useTranslation } from 'react-i18next';

import { IBasketItem } from '../../../modules/basket/models';
import calculatePrice from '../../../utils/calculatePrice';

interface IProps {
  currency: string;
  itemType: string;
  color?: boolean;
  price: string | number;
  quantity: number;
  item?: any;
  accommodations?: any;
}

const BasketListItem = (props: IProps) => {
  const { itemType, price, currency, color, item, quantity, accommodations } =
    props;
  const { t } = useTranslation('common');

  const { getPriceDisplay } = Hooks.usePriceDisplay(currency);

  const priceAccommodations: number[] = [];
  if (accommodations) {
    for (let i = 0; i < accommodations.length; i++) {
      priceAccommodations.push(
        calculatePrice(
          accommodations[i].price,
          accommodations[i].daysCount,
          accommodations[i].quantity,
          accommodations[i].additionalGuestPrice,
          accommodations[i].additionalGuestsCount
        )
      );
    }
  }

  const sum: any[] = [];
  if (priceAccommodations.length != 0) {
    sum.push(priceAccommodations.reduce((a: number, b: number) => a + b));
  }

  const translateItemType = (value: string) => {
    switch (value) {
      case 'Therms':
        return 'Aquapark';
      case 'Accommodation':
        return t('basket.accommodation');
      case 'SkiPass':
        return t('basket.ski_pass');
      case 'SkiRental':
        return t('basket.ski_rental');
      case 'SkiSchool':
        return t('basket.ski_school');
      case 'Parking':
        return t('basket.parking');
      default:
        return t('basket.total');
    }
  };

  return (
    <li className="basket-list-item">
      {itemType === 'Accommodation' ? (
        <div
          className={
            color
              ? 'basket-list-item__name berg-components-basket-item-text-grey '
              : 'basket-list-item__name'
          }
        >
          {translateItemType(itemType)} -{' '}
          {t('basket.room', { count: quantity })} x{' '}
          {t('basket.day', {
            count:
              item &&
              item.find((typ: IBasketItem) => typ?.itemType === 'Accommodation')
                .daysCount,
          })}
        </div>
      ) : (
        <div
          className={
            color
              ? 'basket-list-item__name berg-components-basket-item-text-grey '
              : 'basket-list-item__name'
          }
        >
          {translateItemType(itemType)}
        </div>
      )}
      {itemType === 'Accommodation' ? (
        <div>{getPriceDisplay(sum[0])}</div>
      ) : (
        <div>{getPriceDisplay(price)}</div>
      )}
    </li>
  );
};

export default BasketListItem;
