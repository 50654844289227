import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '..';
import Icons from '../../assets/icons';

const SearchDate = () => {
  const { t } = useTranslation('components');

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [dates, setDates] = useState<string[]>([]);

  const toggleCalendarVisibility = () => {
    setIsCalendarVisible((prev) => !prev);
  };

  const handleDatesChange = (dates: string[]) => {
    setDates(dates);
    setIsCalendarVisible(false);
  };

  return (
    <div className="search-date">
      <span onClick={toggleCalendarVisibility}>
        <Icons.Calendar />
        <span className="search-date__label">
          {dates.map((date) => moment(date).format('DD.MM.YYYY')).join(' - ') ||
            t('search.date')}
        </span>
      </span>
      {isCalendarVisible && (
        <div className="search-date__calendar">
          <Components.RangeCalendar
            name="accommodation"
            startDate={dates[0]}
            endDate={dates[1]}
            onDatesChange={(dates: string[]) => handleDatesChange(dates)}
          />
        </div>
      )}
      <input
        className="search-number-field__input"
        type="text"
        defaultValue={dates[0]}
        name="startDate"
      />
      <input
        className="search-number-field__input"
        type="text"
        defaultValue={dates[1]}
        name="endDate"
      />
    </div>
  );
};

export default SearchDate;
