import Components from '../..';

interface IProps {
  id: string;
  price: string;
  name: string;
  count: number;
  onCountChange: ({ count, id }: { count: number; id: string }) => void;
}

const TicketType = (props: IProps) => {
  const { count, id, price, name } = props;

  const onCountChange = (count: number) => {
    props.onCountChange({ count, id });
  };

  return (
    <div className="berg-components-ticket-type">
      <div>{name}</div>
      <div>{price}</div>
      <Components.NumberCounter
        counter={count}
        onChange={onCountChange}
        maxCounter={99}
      />
    </div>
  );
};

export default TicketType;
