import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Components from '../../../components';
import Hooks from '../../../hooks';
import {
  getBasketCompanyData,
  getBasketContactData,
} from '../../../modules/basket/selectors';
import {
  updateCompanyData,
  updateContactData,
} from '../../../modules/basket/slice';
import {
  CompanyData,
  ContactData,
} from '../../../modules/common/models/orderData';
import './CartData.scss';

interface IQuickForm {
  name: string;
  lastName: string;
  mail: string;
  mail2?: string;
}

const INITIAL_DATA = {
  name: '',
  lastName: '',
  mail: '',
};

const INITIAL_COMPANY_DATA: CompanyData = {
  nip: '',
  city: '',
  name: '',
  postalCode: '',
  street: '',
  houseNumber: '',
  contactPerson: '',
};

const QuickForm = () => {
  const { t } = useTranslation(['components', 'common']);
  const dispatch = Hooks.useAppDispatch();
  const [matchError, setMatchError] = useState<string | undefined>();
  const [formSubmited, setFormSubmited] = useState<boolean>(false);
  const navigate = useNavigate();
  const contactFormData = Hooks.useAppSelector((s) =>
    getBasketContactData(s.basket)
  );
  const formCompanyData = Hooks.useAppSelector((s) =>
    getBasketCompanyData(s.basket)
  );

  const [quickForm, setQuickForm] = useState<IQuickForm>(
    contactFormData ?? INITIAL_DATA
  );

  const [contactCompanyDataForm, setContactCompanyDataForm] =
    useState<CompanyData>(formCompanyData ?? INITIAL_COMPANY_DATA);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);

    setQuickForm({
      ...quickForm,
      [event.target.id]: event.target.value,
    });
    if (formSubmited) {
      if (quickForm.mail !== quickForm.mail2) {
        setMatchError(t('common:form_errors.email_not_match'));
      } else {
        setMatchError(undefined);
      }
    }
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactCompanyDataForm({
      ...contactCompanyDataForm,
      [event.target.id.replace('company-', '')]: event.target.value,
    });
  };

  // const handleFormSwitch = () => {
  //   if (quickForm.mail !== quickForm.mail2) {
  //     setMatchError(t('common:form_errors.email_not_match'));
  //   } else {
  //     const contactData = {
  //       ...quickForm,
  //     } as ContactData;

  //     delete contactData.mail2;

  //     dispatch(updateContactData(contactData));
  //   }
  // };

  const [wantsInvoice, setWantsInvoice] = useState<boolean>(false);
  const handleChangeShowCompanyData = () => {
    setWantsInvoice((value) => !value);
  };

  const formHandleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmited(true);
    if (quickForm.mail !== quickForm.mail2) {
      setMatchError(t('common:form_errors.email_not_match'));
    } else {
      const contactData = {
        ...quickForm,
      } as ContactData;

      delete contactData.mail2;

      dispatch(updateContactData(contactData));
      wantsInvoice && dispatch(updateCompanyData(contactCompanyDataForm));
      navigate('/cart/summary');
    }
  };

  return (
    <form onSubmit={formHandleSubmit}>
      <Components.BergStack direction="column" spacing={4}>
        <Components.BergTextInput
          id="name"
          label={t('components:cart_data.name_label')}
          onChange={handleChange}
          value={quickForm.name}
          required
          maxLength={256}
        ></Components.BergTextInput>
        <Components.BergTextInput
          id="lastName"
          label={t('components:cart_data.surname_label')}
          onChange={handleChange}
          value={quickForm.lastName}
          required
          maxLength={256}
        ></Components.BergTextInput>
        <Components.BergTextInput
          id="mail"
          label={t('components:cart_data.email_label')}
          onChange={handleChange}
          value={quickForm.mail}
          type="email"
          required
          maxLength={50}
        ></Components.BergTextInput>
        <Components.BergTextInput
          id="mail2"
          label={t('components:cart_data.confirmation_email_label')}
          onChange={handleChange}
          type="email"
          error={matchError}
          required
          maxLength={50}
        ></Components.BergTextInput>
        <div className="berg-pages-cart-data__footer">
          <label role="placeholder" />
          <Components.BergCheckbox
            onValueChange={handleChangeShowCompanyData}
            name={'gg'}
            label={t('components:cart_data.want_invoice')}
          />
          {/* <button type="button" onClick={handleFormSwitch}>
          
          </button> */}
          <p>{t('components:cart_data.required_info')}</p>
        </div>
        {wantsInvoice && (
          <>
            <Components.BergTextInput
              id="company-nip"
              label={t('components:cart_data.nip_label')}
              onChange={handleCompanyChange}
              value={contactCompanyDataForm.nip}
              required
              maxLength={20}
            ></Components.BergTextInput>
            <Components.BergTextInput
              id="company-name"
              label={t('components:cart_data.company_label')}
              onChange={handleCompanyChange}
              value={contactCompanyDataForm.name}
              required
              maxLength={256}
            ></Components.BergTextInput>
            <Components.BergTextInput
              id="company-street"
              label={t('components:cart_data.street_label')}
              onChange={handleCompanyChange}
              value={contactCompanyDataForm.street}
              maxLength={256}
            ></Components.BergTextInput>
            <Components.BergTextInput
              id="company-houseNumber"
              label={t('components:cart_data.house_number')}
              onChange={handleCompanyChange}
              value={contactCompanyDataForm.houseNumber}
              maxLength={256}
            ></Components.BergTextInput>
            <Components.BergTextInput
              id="company-city"
              label={t('components:cart_data.city_label')}
              onChange={handleCompanyChange}
              value={contactCompanyDataForm.city}
              maxLength={256}
            ></Components.BergTextInput>
            <Components.BergTextInput
              id="company-postalCode"
              label={t('components:cart_data.posal_code_label')}
              onChange={handleCompanyChange}
              value={contactCompanyDataForm.postalCode}
              maxLength={10}
            ></Components.BergTextInput>
          </>
        )}
      </Components.BergStack>
      <Components.BergDevider />
      <Components.BergStack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="quick-form__buttons"
      >
        <Components.BergButton
          color="white-border"
          label={t('components:cart_data.back_to_cart_button')}
          onClick={() => navigate('/cart')}
        ></Components.BergButton>
        <Components.BergButton
          color="primary"
          type="submit"
          label={t('components:cart_data.summary_button')}
        ></Components.BergButton>
      </Components.BergStack>
    </form>
  );
};

export default QuickForm;
