import moment, { Moment } from 'moment';
import Day from './Day';

interface IDaysProps {
  date: Moment;
  startDate: Moment | null;
  endDate: Moment | null;
  onClick: (date: Moment) => void;
  onHover: (date: Moment) => void;
  isDisabled: boolean;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const Days = (props: IDaysProps) => {
  const {
    date,
    startDate,
    endDate,
    onClick,
    onHover,
    isDisabled,
    onMouseLeave,
  } = props;

  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate =
    moment(date).startOf('month').day() === 0
      ? 7
      : moment(date).startOf('month').day();
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextMonth = moment(date).add(1, 'month');
  const firstLayerBg = [];
  const days = [];
  const labels = [];

  for (let i = 1; i <= 7; i++) {
    labels.push(
      <span key={i} className="label">
        {moment().day(i).format('ddd')}
      </span>
    );
  }

  for (let i = firstDayDate; i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);

    firstLayerBg.push(
      <Day
        key={moment(previousMonth).format('DD MM YYYY')}
        currentDate={date}
        date={moment(previousMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );

    days.push(
      <Day
        key={moment(previousMonth).format('DD MM YYYY')}
        onClick={onClick}
        onHover={onHover}
        currentDate={date}
        date={moment(previousMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    firstLayerBg.push(
      <Day
        key={moment(thisDate).format('DD MM YYYY')}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
      />
    );

    days.push(
      <Day
        key={moment(thisDate).format('DD MM YYYY')}
        onClick={onClick}
        onHover={onHover}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  const daysCount = days.length;

  for (let i = 1; i <= 42 - daysCount; i++) {
    nextMonth.date(i);

    firstLayerBg.push(
      <Day
        key={moment(nextMonth).format('DD MM YYYY')}
        currentDate={date}
        date={moment(nextMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );

    days.push(
      <Day
        key={moment(nextMonth).format('DD MM YYYY')}
        onClick={onClick}
        onHover={onHover}
        currentDate={date}
        date={moment(nextMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  return (
    <div
      aria-disabled={isDisabled}
      className="calendar-days"
      onMouseLeave={onMouseLeave}
    >
      {labels.concat()}
      <div className="calendar-days__days">
        <div className="calendar-days__bg-layer calendar-days__bg-layer--first">
          {firstLayerBg.concat()}
        </div>
        <div className="calendar-days__numbers-layer">{days.concat()}</div>
      </div>
    </div>
  );
};

export default Days;
