import { useState } from 'react';
import { IParking } from '../../modules/parking/models';
import Parking from './Parking';
import Icons from '../../assets/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  parking: IParking[];
}

const ParkingTemplate = (props: IProps) => {
  const { parking } = props;
  const { t } = useTranslation('components');

  const [showMore, setShowMore] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(1);

  const mobile = window.matchMedia('(max-width: 450px)');

  const handleShowMore = () => {
    if (itemsToShow === 1) {
      setShowMore(true);
      setItemsToShow(parking?.length);
    } else {
      setShowMore(false);
      setItemsToShow(1);
    }
  };

  return (
    <div className="berg-components-therms-template">
      {mobile.matches
        ? parking
            ?.slice(0, itemsToShow)
            ?.map((p) => <Parking key={p.id} parking={p} />)
        : parking?.map((p) => <Parking key={p.id} parking={p} />)}

      <div className="berg-components-therms-template__button">
        {mobile.matches &&
          (!showMore ? (
            <a onClick={handleShowMore}>
              <Icons.ArrowsDown />
              <p>{t('parking.more_ticket')}</p>
            </a>
          ) : (
            <a onClick={handleShowMore}>
              <Icons.ArrowsUp />
              <p>{t('parking.expand')}</p>
            </a>
          ))}
      </div>
    </div>
  );
};
export default ParkingTemplate;
