import { IProductType } from '../common/models/productModels';
import { IParking, IParkingStateModel } from './models';

export const getParkingTypes = (state: IParkingStateModel): IProductType[] =>
  state.parkingTypes;

export const getParkingEntries = (state: IParkingStateModel): IParking[] =>
  state.parkingPasses;

export const getParkingDate = (state: IParkingStateModel): string => state.date;
