import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import { IProductType } from '../common/models/productModels';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { setDate } from './slice';
import { IParking, IParkingResponse } from './models';

export const fetchParkingTypes = createAsyncThunk<IProductType[]>(
  actionTypes.FETCH_PARKING_TYPES,
  async (_, thunkApi): Promise<IProductType[]> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductType[]>(
        '/v1/parking/types'
      );

      thunkApi.dispatch(hideLoader());

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchParking = createAsyncThunk(
  actionTypes.FETCH_PARKING,
  async (
    params: { typeId: string; date: string },
    thunkApi
  ): Promise<IParking[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');

    thunkApi.dispatch(setDate(formattedDate));
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IParkingResponse>(
        '/v1/parking',
        {
          params: {
            typeId: params.typeId,
            date: formattedDate,
          },
        }
      );

      thunkApi.dispatch(hideLoader());

      return response.data.content;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
