import { useTranslation } from 'react-i18next';
import Components from '..';
import { IDropdownOption } from '../Dropdown/Dropdown';

interface IProps {
  index: number;
  defaultValue: string;
  onChange?: (value: string) => void;
}

const ChildAgeSelect = (props: IProps) => {
  const { index, onChange, defaultValue } = props;

  const { t } = useTranslation('components');

  const options = [
    // { value: '0', label: t('search.less_than_one_year') },
    // { value: '1', label: t('search.one_year') },
    // { value: '2', label: t('search.two_years') },
    { value: '3', label: t('search.three_years') },
    { value: '4', label: t('search.four_years') },
  ] as IDropdownOption[];

  for (let i = 5; i < 18; i++) {
    options.push({
      value: i.toString(),
      label: `${i}  ${t('search.years')}`,
    });
  }

  return (
    <div className="berg-components-choice-number-age-children">
      <p className="berg-components-choice-number-text space">
        {t('choice_number.child')} {index + 1}
      </p>
      <div className="berg-components-choice-number-dropdown">
        <Components.Dropdown
          onChange={onChange}
          options={options}
          name={`child${index}`}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default ChildAgeSelect;
