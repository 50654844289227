import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

interface IProps {
  children: React.ReactNode;
  id: string;
}

const Portal = (props: IProps) => {
  const { children, id } = props;

  const [element, setElement] = useState<HTMLElement>();

  useEffect(() => {
    const node = document.getElementById(id);

    if (node) {
      setElement(node);
    }
  }, [id]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
};

export default Portal;
