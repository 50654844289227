import { combineReducers } from '@reduxjs/toolkit';
import skiPassSlice from '../modules/skiPass/slice';
import basketSlice from '../modules/basket/slice';
import layoutSlice from '../modules/layout/slice';
import locationDetailsSlice from '../modules/location/details/slice';
import weatherSlice from '../modules/location/weather/slice';
import orderSlice from '../modules/order/slice';
import settingsSlice from '../modules/location/settings/slice';
import announcementsSlice from '../modules/location/announcements/slice';
import billboardsSlice from '../modules/location/billboards/slice';
import accommodationSlice from '../modules/accommodation/slice';
import parkingSlice from '../modules/parking/slice';

const rootReducer = combineReducers({
  location: locationDetailsSlice,
  weather: weatherSlice,
  skiPass: skiPassSlice,
  basket: basketSlice,
  layout: layoutSlice,
  order: orderSlice,
  settings: settingsSlice,
  announcements: announcementsSlice,
  billboards: billboardsSlice,
  accommodation: accommodationSlice,
  parking: parkingSlice,
});

export default rootReducer;
