import React, { useContext, useEffect, useState } from 'react';
import MenuItems from './MenuItems';
import { FaBars, FaTimes } from 'react-icons/fa';
import Pictures from '../../assets/picture';
import { Link } from 'react-router-dom';
import './Navigation.scss';
import './ResponsiveNav.scss';
import LanguageDropdown from './LanguageDropdown/LanguageDropdown';
import Basket from './Basket/Basket';
import { NavigationContext } from '../../context/Navigation';
import Weather from './Weather/Weather';
import CtaButton from './CtaButton';

const getWindowSize = () => {
  const { innerWidth } = window;
  return { innerWidth };
};

const Navigation = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { navRef, isMobileMenuVisible, toggleMobileMenuVisibility } =
    useContext(NavigationContext);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  return (
    <nav className="berg-components-navigation">
      <div className="berg-components-navigation-picture">
        <Link to="/">
          <img src={Pictures.LogoColor} alt="logo" />
        </Link>
      </div>
      <ul className="berg-components-navigation-list" ref={navRef}>
        <MenuItems
          isMobileMenuVisible={isMobileMenuVisible}
          toggleMobileMenuVisibility={toggleMobileMenuVisibility}
        />
        <li className="berg-components-menu-item">
          <Weather />
        </li>
        {windowSize.innerWidth >= 1320 && (
          <li className="berg-components-menu-item-shopping-cart">
            <Basket />
          </li>
        )}
        <li className="berg-components-menu-item-language">
          <LanguageDropdown />
        </li>
      </ul>

      <CtaButton />

      {windowSize.innerWidth < 1320 && (
        <div className="berg-components-menu-item-shopping-cart">
          <Basket />
        </div>
      )}
      <span id="mobile-search-btn" />
      <button
        className="berg-components-navigation-hamburger-btn"
        onClick={toggleMobileMenuVisibility}
      >
        {isMobileMenuVisible ? <FaTimes /> : <FaBars />}
      </button>
    </nav>
  );
};

export default Navigation;
