import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductType } from '../common/models/productModels';
import { ISkiPass, ISkiPassStateModel } from './models/skiPass';
import { fetchSkiPasses, fetchSkiPassTypes } from './operations';

const initialState = {} as ISkiPassStateModel;

const skiPassSlice = createSlice({
  name: 'SkiPassTypes',
  initialState,
  reducers: {
    setValidityDate: (state, action: PayloadAction<string>) => {
      state.validityDate = action.payload;
    },
    resetPickedSkiPass: (state) => {
      state.skiPasses = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSkiPassTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSkiPassTypes.fulfilled,
      (state, action: PayloadAction<IProductType[]>) => {
        state.skiPassTypes = action.payload;

        state.loading = false;
      }
    );
    builder.addCase(fetchSkiPasses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSkiPasses.fulfilled,
      (state, action: PayloadAction<ISkiPass[]>) => {
        state.skiPasses = action.payload;

        state.loading = false;
      }
    );
  },
});

export const { setValidityDate, resetPickedSkiPass } = skiPassSlice.actions;
export default skiPassSlice.reducer;
