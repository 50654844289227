import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocationDetailsModel } from './models/locationDetails';
import { fetchRestaurantDetails, fetchSkiPassDetails, fetchSkiRentalPassDetails, fetchSkiSchoolPassDetails, fetchThermsPassDetails } from './operations';
import { ISeasonResponseState } from './models/locationDetails';

const initialState = {} as ILocationDetailsModel;

const locationDetailsSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSkiPassDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThermsPassDetails.pending, (state) => {
      state.loading = true;
    });   
    builder.addCase(fetchSkiSchoolPassDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSkiRentalPassDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRestaurantDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSkiPassDetails.fulfilled,
      (state, action: PayloadAction<ISeasonResponseState>) => {
        state.skiPass = action.payload.response;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchThermsPassDetails.fulfilled,
      (state, action: PayloadAction<ISeasonResponseState>) => {
        state.therms = action.payload.response;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchSkiSchoolPassDetails.fulfilled,
      (state, action: PayloadAction<ISeasonResponseState>) => {
        state.skiSchool = action.payload.response;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchSkiRentalPassDetails.fulfilled,
      (state, action: PayloadAction<ISeasonResponseState>) => {
        state.skiRental = action.payload.response;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchRestaurantDetails.fulfilled,
      (state, action: PayloadAction<ISeasonResponseState>) => {
        state.restaurant = action.payload.response;
        state.loading = false;
      }
    );
  },
});

export default locationDetailsSlice.reducer;
