import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Season } from '../../config/globalVariables';
import { SeasonContext } from '../../context/SeasonContext';

import './SeasonSelector.scss';

const SeasonSelector = () => {
  const { season, setSeason, setIsSeasonSelectorTouched } =
    useContext(SeasonContext);

  const { t } = useTranslation('components');

  const handleSeasonSwitch = (season: Season) => {
    setSeason?.(season);
    setIsSeasonSelectorTouched?.(true);
  };

  return (
    <div className="berg-components-season-selector">
      <div
        className="berg-components-season-selector-tab"
        aria-selected={season === Season.Winter}
        onClick={() => {
          handleSeasonSwitch(Season.Winter);
        }}
      >
        {t('season_selector.winter')}
      </div>
      <div
        className="berg-components-season-selector-tab"
        aria-selected={season === Season.Summer}
        onClick={() => {
          handleSeasonSwitch(Season.Summer);
        }}
      >
        {t('season_selector.summer')}
      </div>
    </div>
  );
};

export default SeasonSelector;
