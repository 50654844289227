import React from 'react';

const Meta = () => (
  <>
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="index.scss"
      type="text/css"
      media="all"
      title="default-style"
    />
    <meta name="description" content="E-commerce landing page" />
    <meta name="author" content="BergRegions.pl" />
    <meta name="copyright" content="(c) 2022 BergRegions.pl" />
  </>
);

export default Meta;
