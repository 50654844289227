import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../..';
import Pictures from '../../../assets/picture';
import { validateSkiPass } from '../../../modules/skiPass/operations';
import { IDataCarrierIdInput, IPickedTickets } from '../types';

interface IProps {
  ticket: IDataCarrierIdInput;
  setInputsState: React.Dispatch<React.SetStateAction<IDataCarrierIdInput[]>>;
  setPickedTickets: React.Dispatch<React.SetStateAction<IPickedTickets[]>>;
}

enum Statuses {
  loading = 'loading',
  valid = 'valid',
  invalid = 'invalid',
}

const Input = (props: IProps) => {
  const { ticket, setInputsState } = props;

  const { t } = useTranslation('components');

  const [value, setValue] = useState<string>(ticket.dataCarrierId ?? '');
  const [isActive, setIsActive] = useState<boolean>(false);

  const { isDirty, isLoading, errorMsg } = ticket;

  const updateInputsState = (param: {
    [key: string]: boolean | string | null;
  }) => {
    setInputsState((prev) =>
      prev.map((input) => {
        if (input.uniqueId === ticket.uniqueId) {
          return { ...input, ...param };
        } else return input;
      })
    );
  };

  const setIsChecked = (isChecked: boolean) => {
    updateInputsState({ isChecked });
  };

  const setIsDirty = (isDirty: boolean) => {
    updateInputsState({ isDirty });
  };

  const setIsLoading = (isLoading: boolean) => {
    updateInputsState({ isLoading });
  };

  const setErrorMsg = (errorMsg: string | null) => {
    updateInputsState({ errorMsg });
  };

  const setDataCarrierId = (dataCarrierId: string) => {
    updateInputsState({ dataCarrierId });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsChecked(false);
    setIsActive(true);
    setErrorMsg(null);

    if (e.target.value !== '') {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  };

  const onBlur = () => {
    const dataCarrierId = value;

    setIsActive(false);
    setIsLoading(true);

    if (isDirty) {
      validateSkiPass(value).then((data) => {
        setIsLoading(false);
        setErrorMsg(data.errorMessage);
        setIsChecked(true);

        if (data.valid) {
          setDataCarrierId(dataCarrierId);
        } else {
          setDataCarrierId('');
        }
      });
    }
  };

  const getStatus = () => {
    if (isActive) {
      return;
    }

    if (isDirty && isLoading) {
      return Statuses.loading;
    }

    if (ticket.dataCarrierId) {
      return Statuses.valid;
    }

    if (isDirty && !isLoading && !!errorMsg) {
      return Statuses.invalid;
    }
  };

  return (
    <>
      <label htmlFor={ticket.uniqueId}>{ticket.name}</label>
      <div className="berg-components-media-data-aside">
        <Components.Tooltip
          content={t('media_data.where_to_find_card_number')}
        />
        <input
          name={ticket.uniqueId}
          type="text"
          id={ticket.uniqueId}
          placeholder="01-1614 2029 1635 3599 9449-2"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
        {getStatus() === Statuses.loading && <Components.Spinner />}
        {getStatus() === Statuses.valid && (
          <img
            src={Pictures.Check}
            alt="check"
            className="berg-components-media-data-img"
          />
        )}
        {getStatus() === Statuses.invalid && (
          <div className="error">
            <Components.Tooltip content={errorMsg}>
              {
                <img
                  src={Pictures.Error}
                  alt="error"
                  className="berg-components-media-data-img"
                />
              }
            </Components.Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
