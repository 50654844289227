import React from 'react';
import './RoomFullCard.scss';

import { IAccommodation } from '../../modules/accommodation/models';
import BergDevider from '../Storybook/BergDevider/BergDevider';
import { Trans, useTranslation } from 'react-i18next';
import Hooks from '../../hooks';
import Components from '..';

interface IProps {
  daysCount: number;
  accommodation: IAccommodation;
  onSubmitClick: (accommodation: IAccommodation) => void;

  quantityRoom: number;
  quantitySupply: number;
  onCountChangeRoom: (count: number) => void;
  onCountChangeSupply: (count: number) => void;
}

const RoomPriceSummary = (props: IProps) => {
  const {
    accommodation,
    daysCount,
    onSubmitClick,

    quantityRoom,
    quantitySupply,
    onCountChangeRoom,
    onCountChangeSupply,
  } = props;
  const { t } = useTranslation('components');

  const { getPriceDisplay } = Hooks.usePriceDisplay(
    accommodation.price.currency
  );

  return (
    <section className="berg-components-room-price-summary">
      {/* <div className="berg-components-room-price-summary__header">
        Sezon niski ? z api
      </div> */}
      {/* <BergDevider color="lighter-grey"></BergDevider> */}
      <div className="berg-components-room-price-summary__price">
        <div>
          <label>
            <Trans
              values={{ people: accommodation.guestsIncludedInPrice }}
              ns="components"
              i18nKey="room_price_summary.price_for_room"
              components={{
                br: <br />,
              }}
            ></Trans>
          </label>
          <p>
            {getPriceDisplay(+accommodation.price.grossAmount)}
            <span>{t('room_price_summary.night')}</span>
          </p>
        </div>
        <div>
          <label>{t('room_price_summary.count_for_room')}</label>
          <Components.NumberCounter
            counter={quantityRoom}
            onChange={onCountChangeRoom}
            maxCounter={accommodation.availability}
          />
        </div>
      </div>
      <BergDevider color="lighter-grey"></BergDevider>
      <div className="berg-components-room-price-summary__supply">
        <div>
          {t('room_price_summary.supply', {
            price: getPriceDisplay(accommodation.price.additionalGuestPrice),
          })}{' '}
        </div>
        {/* <div>{t('room_price_summary.child')} </div> */}
        <div>
          <label>{t('room_price_summary.count_supply')}</label>
          <Components.NumberCounter
            counter={quantitySupply}
            onChange={onCountChangeSupply}
            maxCounter={accommodation?.maxAdditionalGuests * quantityRoom}
          />
        </div>
        <div>
          {t('room_price_summary.max_count_supply', {
            count: accommodation?.maxAdditionalGuests * quantityRoom,
          })}{' '}
        </div>
      </div>
      <BergDevider color="lighter-grey"></BergDevider>
      <div className="berg-components-room-price-summary__total-price">
        <p>{t('room_price_summary.total')}:</p>
        <p>
          {quantityRoom === 0
            ? getPriceDisplay(
                +(accommodation?.price.grossAmount ?? 0) * daysCount
              )
            : getPriceDisplay(
                +(accommodation?.price.grossAmount ?? 0) *
                  daysCount *
                  quantityRoom +
                  quantitySupply *
                    accommodation.price.additionalGuestPrice *
                    daysCount
              )}
        </p>
      </div>
      <BergDevider color="lighter-grey"></BergDevider>
      <div className="berg-components-room-price-summary__bed-layout">
        <p>{t('room_price_summary.bed_layout')}</p>
        <div>
          {/* <p>{t('room_price_summary.price_for_double_bed')}</p> */}
          <div>
            <p>
              {
                accommodation.bedTypes.find(
                  (bed) => bed.icon === 'double' || 'single'
                )?.count
              }{' '}
              {
                accommodation.bedTypes.find(
                  (bed) => bed.icon === 'double' || 'single'
                )?.name
              }
            </p>
            <p>
              {getPriceDisplay(
                accommodation.price.grossAmount * daysCount * quantityRoom
              )}
            </p>
          </div>
          {quantitySupply !== 0 && (
            <div>
              <p>
                {t('room_price_summary.price_for_supply', {
                  count: quantitySupply,
                })}
              </p>
              <p>
                {getPriceDisplay(
                  accommodation.price.additionalGuestPrice *
                    daysCount *
                    quantitySupply
                )}
              </p>
            </div>
          )}
        </div>
      </div>
      <Components.BergButton
        label={t('room_full_card.submit')}
        color="primary-dark"
        onClick={() => onSubmitClick(accommodation)}
      />
    </section>
  );
};

export default RoomPriceSummary;
