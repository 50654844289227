import { useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import './CollapseList.scss';

interface IProps {
  buttonName: string;
  items: string[];
}

const CollapseList = (props: IProps) => {
  const { buttonName, items } = props;
  const [showList, setShowList] = useState<boolean>(false);

  const toggleShowListHandler = () => {
    setShowList((prev) => !prev);
  };

  return (
    <div className="berg-components-collapse-list">
      <button onClick={toggleShowListHandler}>
        {buttonName} {showList ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      {showList && (
        <ol>
          {items?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default CollapseList;
