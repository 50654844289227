import React from 'react';
import './RoomCard.scss';
import { useTranslation } from 'react-i18next';
import { IAccommodation } from '../../modules/accommodation/models';
import Hooks from '../../hooks';
import Components from '..';
import Icon from './Icon';
import Pictures from '../../assets/picture';

interface IProps {
  daysCount: number;
  accommodation: IAccommodation;

  onRoomCardClick: (id: string) => void;
}

const RoomCard = ({ daysCount, accommodation, onRoomCardClick }: IProps) => {
  const { t } = useTranslation('components');

  const { getPriceDisplay } = Hooks.usePriceDisplay(
    accommodation.price.currency
  );

  return (
    <section
      className="berg-components-room-card"
      aria-disabled={accommodation.availability <= 0}
      data-unavailable={t('list_of_rooms.unavailable')}
      onClick={
        accommodation.availability <= 0
          ? undefined
          : () => onRoomCardClick(accommodation.id)
      }
    >
      <div className="berg-components-room-card__cover-image">
        <img
          src={accommodation.coverImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = Pictures.RoomPlaceholder;
          }}
        />
      </div>

      <div className="berg-components-room-card__content">
        <h4>{accommodation.name}</h4>
        <p>
          {t('list_of_rooms.room_for', { people: accommodation?.maxOccupancy })}
        </p>
        <div className="berg-components-room-card__bed-types">
          {accommodation.bedTypes.map(({ icon, name, count }, index) => (
            <div key={index}>
              <Icon icon={icon} />
              <span>{count} x</span>
              <span>{name}</span>
            </div>
          ))}
        </div>
        <div className="berg-components-room-card__price">
          <span>{`${getPriceDisplay(+accommodation.price.grossAmount)}/${t(
            'list_of_rooms.single_price'
          )}`}</span>
          <span>
            {`- ${t('list_of_rooms.sum')} ${getPriceDisplay(
              +accommodation.price.grossAmount * daysCount
            )}`}
          </span>
        </div>
        {/* <div className="berg-components-room-card__price-for-all-people">
          {t('list_of_rooms.price_for_all_people', {
            people: accommodation.guestsIncludedInPrice,
          })}
        </div> */}
        <div className="berg-components-room-card__availability">
          {t('list_of_rooms.availability', {
            count: accommodation.availability,
          })}
        </div>
        <Components.BergButton
          label={t('list_of_rooms.submit')}
          color="primary-dark"
          // onClick={() => onSubmitClick(accommodation)}
        />
      </div>
    </section>
  );
};

export default RoomCard;
