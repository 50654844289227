const calculatePrice = (
  price: number,
  quantity: number,
  quantityRoom?: number,
  priceSupply?: number,
  quantitySupply?: number
): number =>
  Math.round(
    (price * quantity * (quantityRoom || 1) +
      (priceSupply || 0) *
        (quantitySupply || 0) *
        quantity) /*+ Number.EPSILON*/ *
      100
  ) / 100;
export default calculatePrice;
