import { useContext, useState } from 'react';
import Components from '../../components';
import { FilterBarContext } from '../../components/FilterBar/FilterBar';

interface IProps {
  onDateChange: (date: string) => void;
  date: string | null;
}

const Calendar = (props: IProps) => {
  const [date, setDate] = useState<string | null>(props.date);

  const { setIsCalendarVisible } = useContext(FilterBarContext);

  const onDateChange = (dateParam: string) => {
    setDate(dateParam);
    props.onDateChange(dateParam);
    setIsCalendarVisible?.(false);
  };

  return (
    <div className="activities-calendar">
      <div className="activities-calendar__middle-wrapper">
        <Components.SingleCalendar
          dateChoice={date}
          onDateChange={onDateChange}
        />
      </div>
    </div>
  );
};

export default Calendar;
