import { useState } from 'react';
import './BergCheckbox.scss';
interface IBergCheckbox {
  label?: React.ReactNode;
  name?: string;
  onValueChange?: (value: boolean) => void;
}

const BergCheckbox = ({ label, name, onValueChange }: IBergCheckbox) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
    if (onValueChange) {
      onValueChange(!isChecked);
    }
  };
  return (
    <div className="berg-checkbox">
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={isChecked}
        onChange={handleChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export default BergCheckbox;
