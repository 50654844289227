import './OrderTable.scss';

export interface IColumn {
  header?: string;
  rows: (row: { [key: string]: any }) => React.ReactNode;
  thClassName?: string;
  tdClassName?: string;
}

interface ITableList {
  columns: IColumn[];
  data: object[];
  tableClassName: string;
}

const OrderTable = (props: ITableList) => {
  const { columns, data, tableClassName = '' } = props;

  const inputArray = columns.map(({ rows, ...column }) => ({
    ...column,
    rows: data.map(rows),
  }));

  const rowsLengths = inputArray.reduce(
    (lengths: number[], column) => [...lengths, column.rows.length],
    []
  );

  const numberOfRows = Math.max(...rowsLengths);

  const convertColumnsToRows = () => {
    const outputArray = [];

    for (let i = 0; i < numberOfRows; i++) {
      const row = [];

      for (let j = 0; j < inputArray.length; j++) {
        row.push(inputArray[j].rows[i]);
      }

      outputArray.push(row);
    }

    return outputArray;
  };

  return (
    <table
      className={['berg-components-order-table', tableClassName].join(' ')}
    >
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th className={column.thClassName} key={index}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {convertColumnsToRows().map((row, index) => (
          <tr key={index}>
            {row.map((cell, id) => (
              <td
                key={id}
                data-label={id > 0 ? inputArray[id].header : undefined}
                className={inputArray[id].tdClassName}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OrderTable;
