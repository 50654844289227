import { IBasketItem, BasketItemType } from '../../../modules/basket/models';

const getItemsTotalPrices = (
  basketItems: IBasketItem[]
): { itemType: BasketItemType; totalPrice: number; quantity: number }[] => {
  const reduceByItemType = (
    acc: { [key in BasketItemType]: IBasketItem[] },
    curr: IBasketItem
  ) => {
    if (acc[curr.itemType]) {
      acc[curr.itemType].push(curr);
    } else {
      acc[curr.itemType] = [curr];
    }

    return acc;
  };

  const reducedArray = basketItems.reduce(
    reduceByItemType,
    {} as { [key in BasketItemType]: IBasketItem[] }
  );

  const result: {
    itemType: BasketItemType;
    totalPrice: number;
    quantity: number;
  }[] = [];

  for (const group in reducedArray) {
    const itemType = group as BasketItemType;
    result.push({
      itemType,
      totalPrice: reducedArray[itemType].reduce(
        (acc: number, curr: IBasketItem) => acc + curr.price * curr.quantity,
        0
      ),
      quantity: reducedArray[itemType].reduce(
        (quantity: number, curent: IBasketItem) => quantity + curent.quantity,
        0
      ),
    });
  }

  return result;
};

export default getItemsTotalPrices;
