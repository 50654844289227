import React from "react";
import { Trans } from "react-i18next";
import "./CookiesPolicy.scss";

const CookiesPolicy = () => {
  return (
    <section className="berg-page-cookies-policy">
      <Trans
        ns="pages"
        i18nKey="cookies"
        components={{ h1: <h1 />, p: <p />, br: <br />, h2: <h2 /> }}
      >
        <h1>Zasady dotyczące plików cookie</h1>
        <p>
          Stránka www.meanderoravice.sk používa súbory cookies, ktoré nám
          pomáhajú zabezpečiť lepšie služby. Používaním stránky
          www.meanderoravice.sk vyjadrujete súhlas s použitím cookies v súlade s
          nastavením vášho internetového prehliadača.
          <br />
          V prípade, že navštívite naše stránky a v prehliadači je povolené
          prijímanie súborov cookie, považujeme to za prijatie našich podmienok
          používania súborov cookie.
          <br />V prípade, že si neželáte používanie cookies využite pomocníka
          vo Vašom prehliadači a používanie cookies zakážte.
        </p>
        <h2>Čo sú cookies?</h2>
        <p>
          Súbory cookie sú malé textové súbory, ktoré môžu byť do prehliadača
          odosielané pri návšteve webových stránok a ukladané do vášho
          zariadenia (počítača či iného zariadenia s prístupom na internet, ako
          napr. Smartphone alebo tabletu). Súbory cookie sa ukladajú do
          priečinka pre súbory vášho prehliadača. Cookies zvyčajne obsahujú
          názov webovej stránky, z ktorej pochádzajú, platnosť a hodnotu. Pri
          ďalšej návšteve stránky webový prehliadač znovu načíta súbory cookie a
          tieto informácie odošle späť webovej stránke, ktorá pôvodne cookies
          vytvorila. Súbory cookies, ktoré používa stránka
          www.meanderoravice.sk, nijako neohrozujú váš počítač.
        </p>
        <h2>Prečo a ako používame cookies</h2>
        <p>
          Cookies používame predovšetkým z dôvodu, že chceme skvalitňovať naše
          služby a spríjemniť (opakovaný) pobyt na stránke našim stálym
          zákazníkom, prispôsobiť je ich potrebám a mať možnosť na základe
          všeobecného správania rozvíjať naše stránky. Ďalej súbory cookies
          využívame na meranie a optimalizáciu reklamných kampaní v online
          prostredí. Na našich stránkach môžu byť použité dočasné a trvalé
          cookies. Dočasné sa uchovávajú vo vašom zariadení, kým stránku
          neopustíte. Trvalé cookies zostávajú vo vašom zariadení do skončenia
          ich platnosti alebo do ručného vymazanie. Doba, počas ktorej si
          informácie ponechávame, závisí od typu súborov cookie.
        </p>

        <h1>
          Druhy cookies, ktoré používame na stránkach www.meanderoravices.sk
        </h1>
        <h2>Prevádzkové súbory cookie</h2>
        <p>
          Pomocou prevádzkových cookies zhromažďujeme štatistické informácie o
          tom, ako používate naše webové stránky. Tieto technické informácie nám
          napr. Povedia, ktoré časti webovej stránky ste navštívili. Tieto
          cookies nám slúžia na uvedenú analýzu a vylepšovanie našich webových
          stránok z hľadiska obsahu, výkonu a dobré užívateľské skúsenosti na
          stránkach. Ak tieto cookies zakážete, nemôžeme vám zaručiť bezchybný
          chod týchto stránok.
        </p>
        <h2>Základné súbory cookie</h2>
        <p>
          Tieto súbory tvoria základ pre prevádzku našich webových stránok a
          umožňujú používanie základných funkcií, akými sú napríklad zabezpečené
          oblasti alebo online platby. Základnými cookies sú napr. Zapamätanie
          prihlásenie, umožnenie prístupu k zabezpečeným oblastiam bez nutnosti
          opätovného prihlásenia, predvyplnenie formulárov, zapamätanie tovaru v
          košíku a pod. Bez týchto cookies nemôžeme poskytovať služby, ktoré
          tvoria základ našich stránok. Ak tieto cookies zakážete, nebudeme môcť
          zaručiť bezchybný chod našich stránok.
        </p>
        <h2>Funkčné súbory cookie</h2>
        <p>
          Funkčné súbory cookie nie sú síce nevyhnutné, ale pomáhajú nám
          vylepšiť funkčnosť stránok www.meanderoravice.sk. Ide napríklad o
          zapamätanie nastavení zvolených pri predchádzajúcej návšteve stránky,
          napr. rozloženie obsahu a pod., aby ste ich nemusel (a) znovu
          nastavovať. Pomocou týchto cookies napríklad zisťujeme, či vám už bola
          ponúknutá určitá služba. Prípadné vypnutie týchto cookies závažne
          neovplyvní funkčnosť webových stránok.
        </p>
        <h2>Reklamné súbory cookie</h2>
        <p>
          Reklamné súbory cookie môžeme používať na našich webových stránkach na
          zobrazenie obsahu reklám, ktoré sú prispôsobené vašim záujmom. Tieto
          cookies môžu zhromažďovať informácie o vašich zvyklostiach pri
          prezeraní našich webových stránok. Môžu byť využité aj na rozpoznanie
          vašej opätovné návštevy niektorej z webových stránok, ktoré sú
          súčasťou našej siete. Týmto spôsobom môžeme na našich webových
          stránkach poskytovať takýto obsah reklamy, ktorý je pre vás
          relevantnejší, a efektívnejšie tak zacieliť priamu marketingovú
          komunikáciu. Zároveň tiež môžeme obmedziť počty zobrazení reklamy a
          merať efektivitu reklamných kampaní. Naše reklamné kampane prioritne
          nastavujeme tak, aby neboli obťažujúce. Zobrazenie reklamy jednotlivým
          užívateľom obmedzujeme počtom i časom.
        </p>
        <h2>Meranie návštevnosti</h2>
        <p>
          Na našej stránke www.meanderoravice.sk používame merania návštevnosti
          a monitorovanie výkonu reklám v týchto nástrojoch: Google Analytics,
          Google AdWords, Sklik (od spoločnosti Seznam.cz) a Facebook.
          <br />
          Sledujeme výhradne agregované dáta a nijako ich nespájame s vašimi
          osobnými údajmi.
        </p>
      </Trans>
    </section>
  );
};

export default CookiesPolicy;
