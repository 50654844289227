import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWeatherModel, IWeatherResponseState } from './weather.model';
import { fetchWeather } from './operations';

const initialState = {} as IWeatherModel;

const weatherSlice = createSlice({
  name: 'Weather',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchWeather.fulfilled,
      (state, action: PayloadAction<IWeatherResponseState>) => {
        state.data = action.payload.response;
      }
    );
  },
});

export default weatherSlice.reducer;
