import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductType } from '../common/models/productModels';
import { IParking, IParkingStateModel } from './models';
import { fetchParking, fetchParkingTypes } from './operations';

const initialState = {} as IParkingStateModel;

const parkingSlice = createSlice({
  name: 'ParkingTypes',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    resetPickedParkingPasses: (state) => {
      state.parkingPasses = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchParkingTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchParkingTypes.fulfilled,
      (state, action: PayloadAction<IProductType[]>) => {
        state.parkingTypes = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchParking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchParking.fulfilled,
      (state, action: PayloadAction<IParking[]>) => {
        state.parkingPasses = action.payload;
        state.loading = false;
      }
    );
  },
});

export const { setDate, resetPickedParkingPasses } = parkingSlice.actions;
export default parkingSlice.reducer;
