/* eslint-disable max-nested-callbacks */

import { useRef } from 'react';
import { Link } from 'react-router-dom';
import './MenuTemplate.scss';

interface IProps {
  hash: string;
  nav: {
    id: string;
    header?: string;
    menus: {
      id: number;
      subtitle?: string;
      menu: {
        header?: string;
        id: number;
        label: string;
        description: string;
        price: string;
      }[];
    }[];
  }[];
}

const MenuTemplate = (props: IProps) => {
  const { nav, hash } = props;

  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => myRef.current?.scrollIntoView();

  return (
    <div className="berg-components-menu">
      <ul className="berg-components-about-nav">
        {nav?.map((link) => (
          <li key={link.id} onClick={executeScroll}>
            <Link to={`#${link.id}`} data-berg-active={hash === `#${link.id}`}>
              {link.header}
            </Link>
          </li>
        ))}
      </ul>

      <div ref={myRef} className="berg-components-menu-lists">
        {nav?.map((item) => (
          <div
            key={item.id}
            className="berg-components-menu-list"
            data-berg-active={hash === `#${item.id}`}
          >
            <div className="berg-components-menu__header">{item.header}</div>
            {item.menus?.map((foo, index) => (
              <div key={index} className="berg-components-menus">
                <div className="berg-components-menu__subtitle">
                  {foo.subtitle}
                </div>
                {foo.menu.map((m, k) => (
                  <div key={k} className="berg-components-menu-list__item">
                    <div className="berg-components-menu-list__label">
                      <div>{m.label}</div>
                      <div>{m.description}</div>
                    </div>
                    <div className="berg-components-menu-list__price">
                      {m.price}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuTemplate;
