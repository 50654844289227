import React, { useState } from 'react';
import Icons from '../../../assets/icons';
import Hooks from '../../../hooks';
import { useTranslation } from 'react-i18next';
import BasketListItem from './BasketListItem';
import './Basket.scss';
import {
  getBasketItems,
  getBasketItemsByType,
  getBasketTotal,
  getBasketTotalQuantity,
} from '../../../modules/basket/selectors';
import getItemsTotalPrices from './getItemsTotalPrices';
import { useNavigate } from 'react-router-dom';
import { BasketItemType } from '../../../modules/basket/models';

const Basket = () => {
  const { t } = useTranslation('components');
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const basketTotal = Hooks.useAppSelector((s) => getBasketTotal(s.basket));
  const basketItems = Hooks.useAppSelector((s) => getBasketItems(s.basket));
  const basketTotalQuantity = Hooks.useAppSelector((s) =>
    getBasketTotalQuantity(s.basket)
  );
  const accommodations = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Accommodation)
  );
  const itemsTotalPrices = getItemsTotalPrices(basketItems);

  const currentCurrency = basketItems[0]?.currency;

  const toggleVisibility = () => setIsVisible((prev) => !prev);

  const dropdownRef = Hooks.useOutsideClick({
    shouldWatch: isVisible,
    callback: toggleVisibility,
  });

  return (
    <div className="berg-components-basket" ref={dropdownRef}>
      <button
        className="berg-components-basket__btn"
        onClick={toggleVisibility}
      >
        <div className="berg-components-basket__contents-wrapper">
          <Icons.ShoppingBag />
          <div className="berg-components-basket__contents">
            {basketTotalQuantity}
          </div>
        </div>
      </button>
      {isVisible && (
        <div className="berg-components-basket__list-wrapper">
          <div className="berg-components-basket__list-header basket-list-item">
            {t('navigation.shopping_cart.your_basket')}
          </div>
          <ul className="berg-components-basket__list">
            {itemsTotalPrices.map((item, index) => (
              <BasketListItem
                key={index}
                itemType={item.itemType}
                price={item.totalPrice}
                currency={currentCurrency}
                quantity={item.quantity}
                item={basketItems}
                accommodations={accommodations}
              />
            ))}
            <BasketListItem
              itemType={t('navigation.shopping_cart.cart_value')}
              price={basketTotal}
              currency={currentCurrency}
              quantity={basketTotalQuantity}
              color={true}
            />
          </ul>
          <button
            className="berg-components-basket__list-btn"
            onClick={() => {
              setIsVisible(false);
              navigate('/cart');
            }}
          >
            {t('navigation.shopping_cart.add_to_cart')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Basket;
