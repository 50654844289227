import React, { CSSProperties } from 'react';
import { ApplyResponsiveStyles } from '../helpers';
import { direction } from '../types';
import './BergStack.scss';

export interface BergStackProps extends React.ComponentPropsWithoutRef<'div'> {
  direction?: direction;
  // spacing?: ResponsiveStyleValue<number>;
  spacing?: number;
  children?: React.ReactNode;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  alignItems?: 'flex-start' | 'center' | 'end';
  alignSelf?: 'flex-start' | 'center' | 'end';
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  className?: string;
}

const BergStack = ({
  children,
  direction,
  spacing,
  justifyContent,
  alignItems,
  alignSelf,
  pt,
  pb,
  mt,
  mb,
  className,
  ...props
}: BergStackProps) => {
  const directionClass = ApplyResponsiveStyles(direction);
  const style: CSSProperties = {
    gap: `${0.25 * (spacing ?? 1)}rem`,
    justifyContent: justifyContent ?? 'flex-start',
    alignItems: alignItems ?? 'flex-start',
    paddingTop: `${0.5 * (pt ?? 1)}rem`,
    paddingBottom: `${0.5 * (pb ?? 1)}rem`,
    marginTop: `${0.5 * (mt ?? 1)}rem`,
    marginBottom: `${0.5 * (mb ?? 1)}rem`,
    alignSelf: alignSelf ?? 'flex-start',
  };
  return (
    <div
      className={['berg-stack', directionClass, className].join(' ')}
      style={style}
      {...props}
    >
      {children}
    </div>
  );
};

export default BergStack;
