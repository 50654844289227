import { useContext } from "react";
import { Season } from "../../config/globalVariables";
import { SeasonContext } from "../../context/SeasonContext";

interface IProps {
  type: Season;
  children: JSX.Element;
}

const HeaderText = (props: IProps) => {
  const { type, children } = props;

  const { season } = useContext(SeasonContext);

  if (season === type) {
    return <h2>{children}</h2>;
  } else {
    return <p>{children}</p>;
  }
};

export default HeaderText;
