import {
  BasketItemType,
  IAccommodationBasketItem,
  IBasketItem,
  IBasketStateModel,
  IParkingBasketItem,
  ISkiPassBasketItem,
} from '../basket/models';
import { ISkiPass } from '../skiPass/models/skiPass';
import {
  CreateSkiPassOrder,
  CreateSkiPassOrderItem,
  OrderRequest,
  CreateAccommodationOrderItem,
  CreateAccommodationOrder,
  CreateParkingOrder,
  CreateParkingOrderItem,
} from './models/create';
import { IAccommodation } from '../accommodation/models';
import { PaymentMethod, PickupMethod } from '../common/models/enums';
import { ContactData } from '../common/models/orderData';
import { IParking } from '../parking/models';

export const mapBasketToOrderRequest = (
  basket: IBasketStateModel
): OrderRequest =>
  ({
    companyData: basket.companyData,
    contactData: mapToContactData(basket.contactData),
    skiPassOrder: mapToSkiPassOrder(basket.items),
    accommodationOrder: mapToAccommodationOrder(basket.items),
    parkingOrder: mapToParkingOrder(basket.items),
  } as OrderRequest);

const mapToContactData = (contactData?: ContactData) => {
  if (contactData) {
    const newContactData = { ...contactData };

    newContactData.phone = contactData.phone
      ? `+${contactData.phonePrefix}${contactData.phone}`
      : undefined;

    delete newContactData.phonePrefix;

    return newContactData;
  }
};

const mapToSkiPassOrder = (basketItems: IBasketItem[]): CreateSkiPassOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.SkiPass)
    .map((x) => {
      const basketItem = x as ISkiPassBasketItem;
      const skiPass = x.item as ISkiPass;
      return {
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        paymentMethod: skiPass.defaultPaymentMethod,
        pickupMethod: basketItem.pickupMethod,
        productId: skiPass.id,
        validityDate: basketItem.validityDate,
        dataCarrierIds: basketItem.dataCarrierIds,
      } as CreateSkiPassOrderItem;
    });
  return {
    items: items,
  } as CreateSkiPassOrder;
};

const mapToAccommodationOrder = (
  basketItems: IBasketItem[]
): CreateAccommodationOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.Accommodation)
    .map((x) => {
      const basketItem = x as IAccommodationBasketItem;
      const accommodation = x.item as IAccommodation;
      return {
        productId: accommodation.id,
        quantity: basketItem.quantity,
        startDate: basketItem.startDate,
        endDate: basketItem.endDate,
        paymentMethod: PaymentMethod.OnLine,
        pickupMethod: PickupMethod.OnSite,
        adultCount: basketItem.adultCount,
        childrenAges: basketItem.childrenAges,
        additionalGuestsCount: basketItem.additionalGuestsCount,
        daysCount: basketItem.daysCount,
      } as CreateAccommodationOrderItem;
    });
  return {
    items: items,
  } as CreateAccommodationOrder;
};

const mapToParkingOrder = (basketItems: IBasketItem[]): CreateParkingOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.Parking)
    .map((x) => {
      const basketItem = x as IParkingBasketItem;
      const parking = x.item as IParking;
      return {
        productId: parking.id,
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        date: basketItem.date,
        paymentMethod: PaymentMethod.OnLine,
        pickupMethod: PickupMethod.OnSite,
        licencePlates: basketItem.licencePlates,
      } as CreateParkingOrderItem;
    });
  return {
    items: items,
  } as CreateParkingOrder;
};
