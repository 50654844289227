import React, { useMemo, useState } from 'react';
import Components from '../components';

interface IProps {
  buttonClassName?: string;
  dialogClassName?: string;
}

const useDialog = (args?: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const show = () => {
    setIsOpen(true);
  };

  const hide = () => {
    setIsOpen(false);
  };

  const Dialog = useMemo(() => {
    const MyDialog = ({ children }: { children: React.ReactNode }) => (
      <Components.Dialog
        buttonClassName={args?.buttonClassName}
        dialogClassName={args?.dialogClassName}
        handleClose={hide}
        isOpen={isOpen}
      >
        {children}
      </Components.Dialog>
    );

    return MyDialog;
  }, [isOpen]);

  return { show, hide, Dialog };
};

export default useDialog;
