import React from "react";
import "./Banner.scss";

const Banner = () => {
  return (
    <div className="berg-components-banner-wrapper">
      {/* <img
        src={require("../../assets/picture/banner.png")}
        alt="banner-meander"
      /> */}
      <div className="berg-components-banner">
        <div className="berg-components-banner-offer-over-time">
          <p className="berg-components-banner-text">
            <span>{"->"}</span>199 EUR
          </p>
          <p className="berg-components-banner-text-offer">
            Oferta ważna do 30.11
          </p>
        </div>
        <div className="berg-components-banner-offer-price">
          <p className="berg-components-banner-text">275 EUR</p>{" "}
        </div>
        <div className="berg-components-banner-buy-pass">
          <p className="berg-components-banner-text">
            <span>kup</span> karnet
          </p>
        </div>
      </div>
      <p className="berg-components-banner-wrapper-text">
        Karnet sezonowy od 25.12.2022 do ukończenia sezonu narciarskiego
      </p>
    </div>
  );
};

export default Banner;
