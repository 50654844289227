import { direction, ResponsiveNames } from './types';

export const ApplyResponsiveStyles = (
  responsiveStyleValues: direction | undefined
): string => {
  if (typeof responsiveStyleValues === 'string') {
    return responsiveStyleValues === 'column'
      ? 'berg-stack--column'
      : 'berg-stack--row';
  }
  const dict = responsiveStyleValues as {
    [key in ResponsiveNames]?: direction | null;
  };
  if (!dict) {
    return '';
  }
  const classes = [] as string[];
  // TODO: create classes in loop
  if (dict.desktop) {
    if (dict.desktop === 'column') {
      classes.push('berg-stack--desktop-column');
    }
  }
  if (dict.tablet === 'column' || (!dict.tablet && dict.mobile === 'column')) {
    classes.push('berg-stack--tablet-column');
  }

  if (dict.mobile === 'column') {
    classes.push('berg-stack--mobile-column');
  }

  return classes.join(' ');
};
