import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBillboardsModel, IBillboardsResponseState } from './billboards.model';
import { fetchBillboards } from './operations';

const initialState = {} as IBillboardsModel;

const billboardsSlice = createSlice({
  name: 'Billboards',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchBillboards.fulfilled,
      (state, action: PayloadAction<IBillboardsResponseState>) => {
        state.data = action.payload.response;
      }
    );
  },
});

export default billboardsSlice.reducer;
