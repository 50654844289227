/* eslint-disable complexity */
import i18n from '../../i18n';
import Accommodation from './Accommodation';
import PassesAndTickets from './PassesAndTickets/PassesAndTickets';

import Pictures, { TPictures } from '../../assets/picture';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Activities.scss';
import visibleOnRelease from '../../config/visibleOnRelease';

import { useContext, useMemo, useRef } from 'react';
import Components from '../../components';
import { Season } from '../../config/globalVariables';
import { SeasonContext } from '../../context/SeasonContext';
import Parking from './Parking';
import siteSettings from '../../config/siteSettings';

interface IPage {
  path: string;
  type: string;
  title: string;
  icon: TPictures;
  element: JSX.Element;
}

export const activitiesPaths: IPage[] = [
  {
    path: 'tickets',
    type: 'tickets',
    title: i18n.t('pages:activities.tickets.title'),
    icon: 'SkierOnLift' || 'SummerLift',
    element: <PassesAndTickets />,
  },
  {
    path: 'accommodation',
    type: 'accommodation',
    title: i18n.t('pages:activities.accommodation.header'),
    icon: 'Moon',
    element: <Accommodation />,
  },
  {
    path: 'parking',
    type: 'parking',
    title: i18n.t('pages:activities.parking'),
    icon: 'Parking',
    element: <Parking />,
  },
];

const Activities = () => {
  const { t } = useTranslation('pages');

  const { pathname } = useLocation();

  const isFirstLoad = useRef(true);

  const { theme } = useContext(SeasonContext);

  const linkBergRegions = siteSettings.bergRegions;

  const filterActivitiesPaths = useMemo(
    () =>
      activitiesPaths.filter((menu) => {
        let pass = true;

        if (Season.Winter === theme) {
          if (menu.path === 'tickets') {
            menu.icon = 'SkierOnLift';
            menu.title = i18n.t('pages:activities.tickets.title');
            pass = visibleOnRelease.ski;
          }
        } else {
          if (menu.path === 'tickets') {
            menu.icon = 'SummerLift';
            menu.title = i18n.t('pages:activities.tickets.summer_title');
            pass = visibleOnRelease.summerLifts && visibleOnRelease.ski;
          }
        }

        if (menu.path === 'accommodation') {
          pass = visibleOnRelease.acc;
        }
        return pass;
      }) as IPage[],
    [theme]
  );

  if (!theme) {
    return null;
  }

  if (isFirstLoad.current) {
    const currentTabIndex = filterActivitiesPaths.findIndex(
      (activity) => activity.path === pathname.split('/')[2]
    );

    const [currentTab] = filterActivitiesPaths.splice(currentTabIndex, 1);
    filterActivitiesPaths.unshift(currentTab);

    isFirstLoad.current = false;
  }

  return (
    <>
      <Components.PopupControl />
      <section className="berg-pages-activities">
        {filterActivitiesPaths.length > 1 && <h2>{t('activities.heading')}</h2>}
        <div className="berg-pages-activities__buttons">
          {filterActivitiesPaths.length > 1 &&
            filterActivitiesPaths.map((link, index) => (
              <NavLink
                key={index}
                to={link.path}
                className={`berg-pages-activities__button berg-pages-activities__button--${link.type}`}
              >
                <img src={Pictures[link.icon]} alt={link.icon} />
                <span>{link.title}</span>
              </NavLink>
            ))}
        </div>
        <div className="berg-pages-activities__content">
          <div className="berg-pages-activities__content--rectangle"></div>
          <Outlet />
          <hr />
          <a href={linkBergRegions}>
            <img
              src={Pictures.LogoBergColor}
              alt="Logo BergRegions"
              className="berg-pages-activities__content--picture"
            />
          </a>
        </div>
      </section>
    </>
  );
};

export default Activities;
