import { Moment } from 'moment';

interface IHeadingProps {
  date: Moment;
  changeMonth: (month: number) => void;
  resetDate: () => void;
}

const Heading = (props: IHeadingProps) => {
  const { date, changeMonth, resetDate } = props;

  const dateClone = date.clone();

  return (
    <div className="berg-components-single-calendar__nav">
      <button onClick={() => changeMonth(dateClone.month() - 1)}>
        &#8249;
      </button>
      <span onClick={() => resetDate()}>
        {`${dateClone.format('MMMM')} ${dateClone.format('YYYY')}`}
      </span>
      <button onClick={() => changeMonth(dateClone.month() + 1)}>
        &#8250;
      </button>
    </div>
  );
};

export default Heading;
