import Dropdown from './Dropdown/Dropdown';
import { NavLink } from 'react-router-dom';
import { IMenuItem } from './types';
import visibleOnRelease from '../../config/visibleOnRelease';
import { useContext } from 'react';
import { SeasonContext } from '../../context/SeasonContext';
import { Season } from '../../config/globalVariables';
import useAppSelector from '../../hooks/useAppSelector';
import { getTheme } from '../../modules/location/settings/selectors';
import { useTranslation } from 'react-i18next';

interface IProps {
  isMobileMenuVisible?: boolean;
  toggleMobileMenuVisibility?: () => void;
}

const MenuItems = (props: IProps) => {
  const { t } = useTranslation('components');
  const season = useAppSelector((x) => getTheme(x.settings));
  const toggleMobileMenuVisibility = () => {
    if (props.isMobileMenuVisible) {
      props.toggleMobileMenuVisibility?.();
    }
  };

  const { theme } = useContext(SeasonContext);

  const menuItems: IMenuItem[] = [
    {
      id: 1,
      title:
        season === Season.Winter
          ? t('navigation.ski_park')
          : t('navigation.bike_park'),
      submenu: [
        {
          title:
            season === Season.Winter
              ? t('navigation.ski_park')
              : t('navigation.bike_park'),
          url: '/ski-park',
        },
        {
          title: t('navigation.rental'),
          url: '/rental',
        },
      ],
    },
    {
      id: 2,
      title: t('navigation.therms_park'),
      url: '/therms-park',
    },
    {
      id: 3,
      title: t('navigation.accommodation.title'),
      submenu: [
        {
          title: t('navigation.accommodation.rooms'),
          url: '/accommodation',
        },
        {
          title: t('navigation.accommodation.book'),
          url: '/activities/accommodation',
        },
        // {
        //   title: t('navigation.accommodation.promo'),
        //   url: '/promo',
        // },
      ].filter(() => {
        const pass = true;

        return pass;
      }),
    },
    // {
    //   id: 4,
    //   title: t('navigation.parking'),
    //   url: '/activities/parking',
    // },

    {
      id: 5,
      title: t('navigation.tickets.title', {
        ns: 'components',
      }),
      url: '/tickets',
      submenu: [
        {
          title: t('navigation.tickets.ski_park'),
          url: '/activities/tickets',
        },
        {
          title: t('navigation.tickets.rental_ski'),
          url: '/activities/rental-ski',
        },
        {
          title: t('navigation.tickets.ski_school'),
          url: '/activities/ski-school',
        },
        {
          title: t('navigation.tickets.therms_park'),
          url: '/activities/therms-tickets',
        },
        {
          title: t('navigation.tickets.rental_bike'),
          url: '/activities/rental-bike',
        },
        { title: t('navigation.parking'), url: '/activities/parking' },
      ].filter((menu) => {
        let pass = true;

        if (menu.url === '/activities/rental-bike') {
          pass = visibleOnRelease.rentalBike;
        }

        if (Season.Winter === theme) {
          if (menu.url === '/activities/tickets') {
            menu.title = t('navigation.tickets.ski_park');
          }
        } else {
          if (menu.url === '/activities/tickets') {
            menu.title = t('navigation.tickets.ski_park_summer');
            pass = visibleOnRelease.summerLifts;
          }
        }

        if (menu.url === '/activities/rental-ski') {
          pass = Season.Winter === theme;
        }

        if (menu.url === '/activities/ski-school') {
          pass = Season.Winter === theme;
        }
        return pass;
      }),
    },
    {
      id: 6,
      title: t('navigation.price_list'),
      url: '/price-list',
    },
    {
      id: 7,
      title: t('navigation.map'),
      url: '/map',
    },
    {
      id: 8,
      title: t('navigation.webcams'),
      url: '/webcams',
      submenu: [
        {
          title: t('navigation.ski_park_webcams'),
          url: '/ski-park/webcams',
        },
        {
          title: t('navigation.therms_park_webcams'),
          url: '/therms-park/webcams',
        },
      ],
    },
    {
      id: 9,
      title: t('navigation.about_region'),
      url: '/about-region',
    },
  ].filter((menu) => {
    const pass = true;
    if (Season.Winter === theme) {
      if (menu.url === '/tickets') {
        menu.title = t('navigation.tickets.title');
      }
    } else {
      if (menu.url === '/tickets') {
        menu.title = t('navigation.tickets.title_summer');
      }
    }
    return pass;
  });

  return (
    <>
      {menuItems.map((item: IMenuItem, index: number) => {
        if (item.submenu) {
          return (
            item.submenu.length > 0 && (
              <li key={index} className="berg-components-menu-item">
                <Dropdown
                  item={item}
                  onItemClick={toggleMobileMenuVisibility}
                />
              </li>
            )
          );
        } else
          return (
            <li key={index} className="berg-components-menu-item">
              <NavLink onClick={toggleMobileMenuVisibility} to={item.url ?? ''}>
                <span>{item.title}</span>
              </NavLink>
              <span
                aria-hidden
                className="berg-components-menu-item-bold-template"
              >
                {item.title}
              </span>
            </li>
          );
      })}
    </>
  );
};

export default MenuItems;
