interface ISiteSettings {
  instagramUrl?: string;
  facebookUrl?: string;
  bergRegions: string;
}

const siteSettings: ISiteSettings = {
  bergRegions: 'https://bergregions.pl/',
};

export default siteSettings;
