enum actionTypes {
  FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS',
  FETCH_SKI_PASS_DETAILS = 'FETCH_SKI_PASS_DETAILS',
  FETCH_SKI_SCHOOL_PASS_DETAILS = 'FETCH_SKI_SCHOOL_PASS_DETAILS',
  FETCH_THERMS_PASS_DETAILS = 'FETCH_THERMS_PASS_DETAILS',
  FETCH_WEATHER = 'FETCH_WEATHER',
  FETCH_SETTINGS = 'FETCH_SETTINGS',
  FETCH_BILLBOARDS = 'FETCH_BILLBOARDS',
  FETCH_SKI_RENTAL_PASS_DETAILS = 'FETCH_SKI_RENTAL_PASS_DETAILS',
  FETCH_RESTAURANT_DETAILS = 'FETCH_RESTAURANT_DETAILS',
  FETCH_PARKING_DETAILS = 'FETCH_PARKING_DETAILS',
}

export default actionTypes;
