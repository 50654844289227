import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import calculatePrice from '../../utils/calculatePrice';
import { PaymentMethod } from '../common/models/enums';
import { CompanyData, ContactData } from '../common/models/orderData';
import {
  basketExpirationItemKey,
  basketItemKey,
  expirationDateFormat,
} from './const';
import { IBasketItem, IBasketStateModel, IQuantityOperation } from './models';

let initialState = {
  totalPrice: 0,
  totalPriceAtLocation: 0,
  items: [] as IBasketItem[],
  basketCurrency: '',
  showPopups: false,
  lastItemId: '',
} as IBasketStateModel;

const expirationDateValue = localStorage.getItem(basketExpirationItemKey);
if (
  !expirationDateValue ||
  moment(expirationDateValue, expirationDateFormat).isAfter(new Date())
) {
  const localStorageState = localStorage.getItem(basketItemKey);
  if (localStorageState) {
    try {
      initialState = JSON.parse(localStorageState) as IBasketStateModel;
      initialState.showPopups = false;
      console.info('Basket state initialized from local storage');
    } catch (error) {
      console.warn(
        'Cannot parse initial state from local storage',
        localStorageState
      );
      localStorage.removeItem(basketItemKey);
    }
  }
} else {
  console.log('Basket expired');
  localStorage.removeItem(basketExpirationItemKey);
  localStorage.removeItem(basketItemKey);
}

const basketSlice = createSlice({
  name: 'Basket',
  initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<IBasketItem>) => {
      console.log(action.payload);
      if (!state.items) {
        state.items = new Array<IBasketItem>();
      }
      const newBasketItem = action.payload;

      const id = `${newBasketItem.item.id}_${newBasketItem.itemType}_${
        newBasketItem?.date || newBasketItem?.startDate
      }_${newBasketItem.licencePlates?.map((id) => id)}`;
      const date = `${newBasketItem.date}`;
      const startDate = `${newBasketItem.startDate}`;
      const endDate = `${newBasketItem.endDate}`;

      const existingBasketItem = state.items.find(
        (x) =>
          x.id === id &&
          (x.date === date ||
            (x.startDate === startDate && x.endDate === endDate))
      );

      if (existingBasketItem) {
        existingBasketItem.quantity += newBasketItem.quantity;
      } else {
        newBasketItem.id = id;
        state.items.push(action.payload);
      }
      state.lastItemId = id;
      state.showPopups = true;
      updateTotal(state);
      updateLocalStorage(state);
    },
    removeBasketItem: (state, action: PayloadAction<string>) => {
      const itemIndex = state.items.findIndex((x) => x.id === action.payload);
      if (itemIndex >= 0) {
        state.items.splice(itemIndex, 1);
        updateTotal(state);
        updateLocalStorage(state);
      }
      console.warn('Item doesn`t exist in basket.');
    },
    substract: (state, action: PayloadAction<IQuantityOperation>) => {
      const item = state.items.find((x) => x.id === action.payload.basketId);
      if (item) {
        item.quantity += action.payload.changeValueBy;
        if (item.quantity <= 0) {
          state.items.splice(
            state.items.findIndex((x) => x.id === action.payload.basketId),
            1
          );
        }
        updateTotal(state);
        updateLocalStorage(state);
        return;
      }

      console.warn('Item doesn`t exist in basket.');
    },
    updateContactData: (state, action: PayloadAction<ContactData>) => {
      state.contactData = action.payload;
      updateLocalStorage(state);
    },
    updateCompanyData: (state, action: PayloadAction<CompanyData>) => {
      state.companyData = action.payload;
      updateLocalStorage(state);
    },
    clearBasket: (state) => {
      state.companyData = undefined;
      state.contactData = undefined;
      state.totalPrice = 0;
      state.totalPriceAtLocation = 0;
      state.items = [];
      state.basketCurrency = '';
      localStorage.removeItem(basketItemKey);
      localStorage.removeItem(basketExpirationItemKey);
    },
    showPopups: (state) => {
      state.showPopups = true;
    },
    hidePopups: (state) => {
      console.log();
      state.showPopups = false;
    },
  },
});

const updateTotal = (state: IBasketStateModel) => {
  const calculatePriceRecuder = (acc: number, item: IBasketItem) => {
    console.log(
      `Acc: ${acc}, Price: ${item.price}, quantity: ${item.quantity}, dayCount: ${item?.daysCount}, priceSupply: ${item?.additionalGuestPrice},quantitySupply: ${item?.additionalGuestsCount} `
    );
    return (
      acc +
      calculatePrice(
        item.price,
        item?.daysCount || 1,
        item.quantity,
        item?.additionalGuestPrice,
        item?.additionalGuestsCount
      )
    );
  };
  state.totalPrice = state.items.reduce(calculatePriceRecuder, 0);
  state.totalPriceAtLocation = state.items
    .filter((x) => x.item.defaultPaymentMethod === PaymentMethod.OnSite)
    .reduce(calculatePriceRecuder, 0);
  state.basketCurrency = state.items?.[0]?.currency;
};

// const roundPrice = (value: number) =>
//   +Math.round((value * 10 + Number.EPSILON) * 100) / 100;

const updateLocalStorage = (state: IBasketStateModel) => {
  localStorage.setItem(basketItemKey, JSON.stringify(state));
  const expirationDate = moment().add(20, 'minutes');

  localStorage.setItem(
    basketExpirationItemKey,
    expirationDate.format(expirationDateFormat)
  );
};

export const {
  addToBasket,
  removeBasketItem,
  updateContactData,
  updateCompanyData,
  substract,
  clearBasket,
  showPopups,
  hidePopups,
} = basketSlice.actions;
export default basketSlice.reducer;
