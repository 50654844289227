import {
  IAccommodationSearchParams,
  IAccommodationState,
  TAccommodationModel,
} from './models';

export const getAccommodations = (
  state: IAccommodationState
): TAccommodationModel => state.content;

export const getDaysCount = (state: IAccommodationState): number =>
  state.daysCount;

export const getSearchParams = (
  state: IAccommodationState
): IAccommodationSearchParams => {
  if (state.searchParams != undefined) {
    return state.searchParams;
  }
  return {} as IAccommodationSearchParams;
};

export const getOverallAvailability = (state: IAccommodationState): boolean =>
  !state.content?.every((room) => room.availability <= 0);
