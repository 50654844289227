import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './MediaData.scss';
import Input from './Input';
import { IDataCarrierIdInput, IPickedTickets } from '../types';
// import Dialog from "./Dialog";

interface IProps {
  tickets: IPickedTickets[];
  hide: () => void;
  setPickedTickets: Dispatch<SetStateAction<IPickedTickets[]>>;
}

const MediaData = (props: IProps) => {
  const { tickets, hide, setPickedTickets } = props;

  const initialInputsState = tickets.map((ticket) => ({
    ...ticket,
    value: ticket.dataCarrierId,
    isDirty: false,
    isChecked: false,
    isLoading: false,
    errorMsg: null,
  }));

  const [isError, setIsError] = useState<boolean>(false);
  const [isNeedsToBeChecked, setIsNeedsToBeChecked] = useState<boolean>(false);
  const [inputsState, setInputsState] =
    useState<IDataCarrierIdInput[]>(initialInputsState);

  const { t } = useTranslation('components');

  const onSave = () => {
    if (isNeedsToBeChecked) {
      return;
    }

    const setDataCarriersIds = (input: IDataCarrierIdInput) => {
      const assignDataCarrierIds = (ticket: IPickedTickets) => {
        if (ticket.uniqueId === input.uniqueId) {
          return { ...ticket, dataCarrierId: input.dataCarrierId };
        } else return ticket;
      };

      setPickedTickets((prev) => prev.map(assignDataCarrierIds));
    };

    inputsState.forEach(setDataCarriersIds);

    hide();
  };

  useEffect(() => {
    setIsError(
      inputsState.some(
        (input) => (input.isChecked && !!input.errorMsg) || input.isLoading
      )
    );

    setIsNeedsToBeChecked(
      inputsState.some((input) => input.isDirty && !input.isChecked)
    );
  }, [inputsState]);

  return (
    <div className="berg-components-media-data">
      <div className="berg-components-media-data-header">
        <h1 className="berg-components-media-data-header-text">
          {t('media_data.header')}
        </h1>
      </div>
      <div className="berg-components-media-data-form">
        {inputsState.map((ticket) => (
          <Input
            ticket={ticket}
            key={ticket.uniqueId}
            setInputsState={setInputsState}
            setPickedTickets={setPickedTickets}
          />
        ))}
      </div>
      <div className="berg-components-media-data-footer">
        <hr />
        <button type="button" onClick={onSave} disabled={isError}>
          {isNeedsToBeChecked ? t('media_data.check') : t('media_data.submit')}
        </button>
        {/* <Dialog
          text={t("media_data.validation")}
          isOpen={true}
          handleClose={()=>{}}
        /> */}
      </div>
    </div>
  );
};

export default MediaData;
