import { Moment } from 'moment';
import { IRange } from '../modules/location/details/models/locationDetails';

/* eslint-disable complexity */
const isWithinDatesRange = (
  rangeStart: IRange,
  rangeEnd: IRange,
  date: Moment
): boolean => {
  const startMonth = rangeStart.month;
  const startDay = rangeStart.day;
  const endMonth = rangeEnd.month;
  const endDay = rangeEnd.day;
  const currentMonth = date.month() + 1;
  const currentDay = date.date();

  const crossYearRange = startMonth > endMonth;
  if (crossYearRange) {
    return (
      currentMonth > startMonth ||
      (currentMonth === startMonth && currentDay >= startDay) ||
      currentMonth < endMonth ||
      (currentMonth === endMonth && currentDay <= endDay)
    );
  } else {
    return (
      (currentMonth > startMonth ||
        (currentMonth === startMonth && currentDay >= startDay)) &&
      (currentMonth < endMonth ||
        (currentMonth === endMonth && currentDay <= endDay))
    );
  }
};

export default isWithinDatesRange;
