import React from 'react';
import './PoolDescription.scss';
import Icons from '../../assets/icons';
import { Trans } from 'react-i18next';

const PoolDescription = () => (
  <section className="berg-components-pool-description">
    <Icons.Thermometer />
    <span>
      <Trans ns="components" i18nKey="pool_description.temp">
        Temepratura:
        <strong>38°C</strong>
      </Trans>
    </span>
    <Icons.Dimensions />
    <span>
      <Trans ns="components" i18nKey="pool_description.size">
        Wielkość:
        <strong>100㎡</strong>
      </Trans>
    </span>
    <Icons.Depth />
    <span>
      <Trans ns="components" i18nKey="pool_description.depth">
        Maksymalna głębokość:
        <strong>1.80m</strong>
      </Trans>
    </span>
  </section>
);

export default PoolDescription;
