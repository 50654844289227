import { Moment } from 'moment';

interface IHeadingProps {
  date: Moment;
  changeMonth: (date: number) => void;
  changeYear: (date: number) => void;
  resetDate: () => void;
}

const Heading = (props: IHeadingProps) => {
  const { date, changeMonth, resetDate, changeYear } = props;

  const dateClone = date.clone();

  return (
    <div className="berg-components-calendar__nav">
      <button
        type="button"
        onClick={() => {
          changeMonth(dateClone.add(-1, 'M').month());
          if (dateClone.month() === 11) {
            changeYear(dateClone.add(0, 'y').year());
          }
        }}
      >
        &#8249;
      </button>
      <span onClick={() => resetDate()}>
        {`${dateClone.format('MMMM')} ${dateClone.format('YYYY')}`}
      </span>
      <button
        type="button"
        onClick={() => {
          changeMonth(dateClone.add(1, 'M').month());
          if (dateClone.month() === 0) {
            changeYear(dateClone.add(0, 'y').year());
          }
        }}
      >
        &#8250;
      </button>
    </div>
  );
};

export default Heading;
