"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonoChannel = exports.Channel = exports.createBroadcastChannel = exports.MessageType = void 0;
const broadcast_channel_1 = require("broadcast-channel");
var MessageType;
(function (MessageType) {
    MessageType["ForceSend"] = "force-send";
    MessageType["Ping"] = "ping";
    MessageType["Pong"] = "pong";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
function createBroadcastChannel() {
    return new broadcast_channel_1.BroadcastChannel('bf-sdk');
}
exports.createBroadcastChannel = createBroadcastChannel;
class Channel {
    constructor(channel, logger) {
        this.channel = channel;
        this.logger = logger;
        this.leader = false;
        this.onForceSendCb = () => { };
        this.sessionsStatus = {};
        this.channel.onmessage = msg => this.handleMessage(msg);
        this.elector = (0, broadcast_channel_1.createLeaderElection)(this.channel, {});
        this.leaderPromise = this.elector.awaitLeadership();
        this.leaderPromise.then(() => {
            this.logger.log(`Now I'm the leader. 💪`);
            this.leader = true;
        });
    }
    setCurrentSession(session) {
        this.session = session;
    }
    handleMessage(msg) {
        var _a, _b;
        switch (msg.type) {
            case MessageType.ForceSend:
                if (this.leader) {
                    this.onForceSendCb();
                }
                break;
            case MessageType.Ping:
                if (msg.toSessionUUID === ((_a = this.session) === null || _a === void 0 ? void 0 : _a.getUUID())) {
                    this.channel.postMessage({
                        type: MessageType.Pong,
                        fromSessionUUID: msg.toSessionUUID,
                        toSessionUUID: msg.fromSessionUUID,
                    });
                }
                break;
            case MessageType.Pong:
                if (msg.toSessionUUID === ((_b = this.session) === null || _b === void 0 ? void 0 : _b.getUUID())) {
                    this.sessionsStatus[msg.fromSessionUUID] = true;
                }
                break;
        }
    }
    isLeader() {
        return this.leader;
    }
    notifyForceSend() {
        if (this.leader) {
            this.onForceSendCb();
        }
        else {
            this.channel.postMessage({ type: MessageType.ForceSend });
        }
    }
    onForceSend(cb) {
        this.onForceSendCb = cb;
    }
    onHasBecomeLeader() {
        return this.leaderPromise;
    }
    isSessionActive(sessionUUID) {
        return __awaiter(this, void 0, void 0, function* () {
            const isSessionReady = !!this.session;
            if (!isSessionReady || (isSessionReady && this.session.getUUID() === sessionUUID)) {
                return true;
            }
            return new Promise(resolve => {
                const timeout = 250;
                this.sessionsStatus[sessionUUID] = false;
                this.channel.postMessage({
                    type: MessageType.Ping,
                    fromSessionUUID: this.session.getUUID(),
                    toSessionUUID: sessionUUID,
                });
                setTimeout(() => resolve(this.sessionsStatus[sessionUUID]), timeout);
            });
        });
    }
}
exports.Channel = Channel;
class MonoChannel {
    constructor() {
        this.onForceSendCb = () => { };
        this.leaderPromise = Promise.resolve();
    }
    setCurrentSession(session) {
        this.session = session;
    }
    isLeader() {
        return true;
    }
    isSessionActive(sessionUUID) {
        return __awaiter(this, void 0, void 0, function* () {
            const isSessionReady = !!this.session;
            return !this.session || (isSessionReady && this.session.getUUID() === sessionUUID);
        });
    }
    notifyForceSend() {
        this.onForceSendCb();
    }
    onForceSend(cb) {
        this.onForceSendCb = cb;
    }
    onHasBecomeLeader() {
        return this.leaderPromise;
    }
}
exports.MonoChannel = MonoChannel;
