import { useEffect, useRef, useState } from 'react';

interface IProps {
  placeholder: string;
  label: string;
  required: boolean;
  name: string;
}

const TextArea = (props: IProps) => {
  const { placeholder, label, required, name } = props;
  const [value, setValue] = useState<string>('');

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resizeTextArea = () => {
    if (!textAreaRef.current) {
      return;
    }

    if (!value) {
      textAreaRef.current.style.height = '90px';
    } else {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(resizeTextArea, [value]);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <div className="contact-form-input">
      <label htmlFor="contact-message">{label}</label>
      <textarea
        onChange={onChange}
        value={value}
        ref={textAreaRef}
        id="contact-message"
        name={name}
        placeholder={placeholder}
        required={required}
        maxLength={500}
      />
    </div>
  );
};

export default TextArea;
