import moment from 'moment';
import { useMemo } from 'react';
import Hooks from '../../../hooks';
import getIcon from './getIcon';

const Weather = () => {
  const { weatherCode, temperature, sunrise, sunset, localTime } =
    Hooks.useAppSelector((s) => s.weather.data) ?? {};

  const icon = getIcon(weatherCode);
  const format = 'hh:mm';

  const timeOfDay = useMemo(
    () =>
      moment(localTime, format).isBetween(
        moment(sunrise, format),
        moment(sunset, format)
      )
        ? 'day'
        : 'night',
    [localTime, sunrise, sunset]
  );

  return (
    <div className="navigation-weather">
      <img src={require(`./icons/${timeOfDay}/${icon}`)} />
      <div>{`${temperature ?? '---'}\u2009\u2103`}</div>
    </div>
  );
};

export default Weather;
