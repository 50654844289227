import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import { IProductType } from '../common/models/productModels';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import {
  ISkiPass,
  ISkiPassResponse,
  ISkiPassValidationResponse,
} from './models/skiPass';
import { setValidityDate } from './slice';

export const fetchSkiPassTypes = createAsyncThunk(
  actionTypes.FETCH_SKI_PASSES_TYPES,
  async (params: { date: string }, thunkApi): Promise<IProductType[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductType[]>(
        '/v1/skipass/types',
        {
          params: {
            date: formattedDate,
          },
        }
      );

      thunkApi.dispatch(hideLoader());

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchSkiPasses = createAsyncThunk(
  actionTypes.FETCH_SKI_PASSES,
  async (
    params: { typeId: string; date: string },
    thunkApi
  ): Promise<ISkiPass[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');

    thunkApi.dispatch(setValidityDate(formattedDate));
    thunkApi.dispatch(showLoader());

    try {
      const response = await axiosInstance.get<ISkiPassResponse>(
        '/v1/skipass',
        {
          params: {
            typeId: params.typeId,
            date: formattedDate,
          },
        }
      );

      thunkApi.dispatch(hideLoader());

      return response.data.content;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const validateSkiPass = async (
  dataCarrierId: string
): Promise<ISkiPassValidationResponse> => {
  try {
    const response = await axiosInstance.get<ISkiPassValidationResponse>(
      '/v1/skipass/datacarrier/validate',
      { params: { dataCarrierId } }
    );

    return response.data;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};
