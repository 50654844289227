import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './About.scss';
import Tooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

interface IProps {
  hash: string;
  header: {
    heading: string;
    text?: string;
    text2?: JSX.Element;
    span?: string;
    smallImgSrc: string;
    smallImgAlt: string;
    bigImgSrc: string;
    bigImgAlt: string;
    linkLabel?: string;
    linkHref?: string;
    isSeason?: boolean;
  };
  nav?: {
    id: string;
    linkTitle?: string;
    text?: string;
    sections: {
      heading: string;
      text?: string;
      text2?: JSX.Element;
      imgSrc?: string;
      imgAlt?: string;
      imgComponent?: JSX.Element;
      component?: JSX.Element;
    }[];
  }[];
}

const About = (props: IProps) => {
  const { header, nav, hash } = props;

  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => myRef.current?.scrollIntoView();
  const { t } = useTranslation('components');

  return (
    <div className="berg-components-about-wrapper">
      <section className="berg-components-about" data-berg-layout="leftPicture">
        <div>
          <picture>
            <img
              className="berg-components-about-image"
              src={process.env.PUBLIC_URL + header.smallImgSrc}
              alt={header.smallImgAlt}
            />
          </picture>
          <h1>{header.heading}</h1>
          <p>
            {header.text}
            {header.text2}
            {header.span && (
              <span className="berg-components-about-span">{header.span}</span>
            )}
          </p>
          <div className="berg-components-about-button">
            {!!header.linkHref && !header.isSeason && (
              <Tooltip content={t('tourist_attractions.season')}>
                <Link to="" className="is-season">
                  {header.linkLabel}
                </Link>
              </Tooltip>
            )}
            {!!header.linkHref && header.isSeason && (
              <Link to={header.linkHref}>{header.linkLabel}</Link>
            )}
          </div>
        </div>
        <picture>
          <img
            src={process.env.PUBLIC_URL + header.bigImgSrc}
            alt={header.bigImgAlt}
            className="berg-components-about-image"
          />
        </picture>
      </section>
      <ul className="berg-components-about-nav">
        {nav?.map((link) => (
          <li key={link.id} onClick={executeScroll}>
            <Link to={`#${link.id}`} data-berg-active={hash === `#${link.id}`}>
              {link.linkTitle}
            </Link>
          </li>
        ))}
      </ul>

      <div ref={myRef} className="berg-components-about-main">
        {nav?.map((link) => (
          <div
            key={link.id}
            className="berg-components-about-main-item"
            data-berg-active={hash === `#${link.id}`}
          >
            {link.text && (
              <div className="berg-components-about-special-text">
                {link.text}
              </div>
            )}
            {link.sections.map((section, index) => {
              const layout = `${index % 2 === 0 ? 'left' : 'right'}Picture`;

              return (
                <section
                  key={index}
                  className="berg-components-about"
                  data-berg-layout={layout}
                >
                  <h2 className="berg-components-about-heading">
                    {section.heading}
                  </h2>
                  <div className="berg-components-about-picture about-picture">
                    {section.imgComponent}
                    {!!section.imgSrc && (
                      <picture>
                        <img
                          className="berg-components-about-image about-image"
                          src={process.env.PUBLIC_URL + section.imgSrc}
                          alt={section.imgAlt}
                        />
                      </picture>
                    )}
                  </div>
                  <p className="berg-components-about-description">
                    {section.text}
                    {section.text2}
                  </p>
                  {section.component}
                </section>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
