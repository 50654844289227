import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchSettings } from './operations';

import { ISettingsModel, ISettingsResponseState } from './settings.model';

const initialState = {} as ISettingsModel;

const settingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loading = true;
    },
    hideLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchSettings.fulfilled,
      (state, action: PayloadAction<ISettingsResponseState>) => {
        state.data = action.payload.response;
      }
    );
  },
});

export const { showLoader, hideLoader } = settingsSlice.actions;

export default settingsSlice.reducer;
