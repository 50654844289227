import React from "react";
import "./RoomDetails.scss";
import Icons from "../../assets/icons";
import { useTranslation } from "react-i18next";

const RoomDetails = () => {
  const { t } = useTranslation("components");
  return (
    <section className="berg-components-room-details">
      <div>
        <label>{t("room_details.double_bed")}</label>
        <Icons.DoubleBed />
        <label>{t("room_details.tv")}</label>
        <Icons.TV />
        <label>{t("room_details.wifi")}</label>
        <Icons.WiFi />
      </div>
      <div>
        <label>{t("room_details.toilet")}</label>
        <Icons.WC />
        <label>{t("room_details.bed")}</label>
        <Icons.Bed />
        <label>{t("room_details.conditioning")}</label>
        <Icons.Conditioning />
      </div>
    </section>
  );
};

export default RoomDetails;
