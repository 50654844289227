import { useTranslation } from 'react-i18next';
import Components from '../..';
import Pictures from '../../../assets/picture';
import './BergPageContentWrapper.scss';
import siteSettings from '../../../config/siteSettings';
import BergDevider from '../BergDevider/BergDevider';

interface IProps extends React.ComponentPropsWithoutRef<'div'> {
  header: string;
  children: React.ReactNode;
}

const BergPageContentWrapper = ({ children, header, ...props }: IProps) => {
  const { t } = useTranslation('pages');
  const linkBergRegions = siteSettings.bergRegions;
  return (
    <>
      <Components.Portal id="sub-header">
        <div className="berg-page-content__rectangle"></div>
        <div className="berg-page-content__header">
          <img src={Pictures.LogoParking} alt="check" />
          <h2>{t('activities.parking')}</h2>
        </div>
        <div className="berg-pages-start__sub-header-placeholder">
          <h2>{header}</h2>
        </div>
      </Components.Portal>
      <div className={['berg-page-content-wrapper'].join(' ')} {...props}>
        {children}
      </div>

      <BergDevider color="ligth-grey" />
      <a href={linkBergRegions}>
        <img
          src={Pictures.LogoBergColor}
          alt="Logo BergRegions"
          className="berg-pages-activities__content--picture"
        />
      </a>
    </>
  );
};

export default BergPageContentWrapper;
