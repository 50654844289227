import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SeasonContext } from '../../context/SeasonContext';
import { Season } from '../../config/globalVariables';

const CtaButton = () => {
  const { theme } = useContext(SeasonContext);

  const { t } = useTranslation('components');

  switch (theme) {
    case Season.Summer:
      return (
        <div className="berg-components-navigation-cta">
          <Link to="/activities/therms-tickets?prefill=today">
            <span>{t('navigation.cta_summer')}</span>
          </Link>
        </div>
      );

    default:
      return (
        <div className="berg-components-navigation-cta">
          <Link to="/activities/tickets?prefill=today">
            <span>
              <Trans ns="components" i18nKey={'navigation.cta_winter'}>
                KUP KARNET <b>ONLINE</b>
              </Trans>
            </span>
          </Link>
        </div>
      );
  }
};

export default CtaButton;
