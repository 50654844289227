import React from 'react';
import Icons from '../../assets/icons';
import Hooks from '../../hooks';
import { WarningType } from '../../modules/common/models/enums';
import { getAnnouncements } from '../../modules/location/announcements/selectors';
import IndicatorCarousel from '../IndicatorCarousel/IndicatorCarousel';
import './Alerts.scss';

const Alert = ({
  text,
  type,
  title,
}: {
  text: string;
  title: string;
  type: WarningType;
}) => (
  <div className="berg-components-alert">
    {type === WarningType.Warning && <Icons.AlertAttention />}
    {type === WarningType.Informational && <Icons.AlertInfo />}
    <p className="berg-components-alert__text">
      <span>{title}: </span>
      {text}
    </p>
  </div>
);

const Alerts = () => {
  const announcements = Hooks.useAppSelector((s) =>
    getAnnouncements(s.announcements)
  );

  return (
    <IndicatorCarousel>
      {announcements?.map((announcement) => (
        <Alert
          key={announcement.id}
          type={announcement.type}
          title={announcement.title}
          text={announcement.text}
        />
      ))}
    </IndicatorCarousel>
  );
};

export default Alerts;
