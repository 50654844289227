import './Search.scss';
import Icons from '../../assets/icons';
import NumberField from './NumberField';
import { useTranslation } from 'react-i18next';
import SearchDate from './SearchDate';
import React, { useContext } from 'react';
import { NavigationContext } from '../../context/Navigation';
import Utils from '../../utils';

const Search = () => {
  const { t } = useTranslation('components');

  const { searchRef } = useContext(NavigationContext);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const values = Utils.getFormData(event);

    console.log(values);
  };

  return (
    <form
      method="get"
      className="berg-components-search"
      onSubmit={handleSubmit}
      ref={searchRef}
    >
      <div className="berg-components-search__title">{t('search.header')}</div>
      <div className="berg-components-search__params-wrapper">
        <SearchDate />
        <NumberField
          icon={<Icons.Adult />}
          label={t('search.adults')}
          name="adults"
        />
        <NumberField
          icon={<Icons.Child />}
          label={t('search.children')}
          name="children"
          tooltip={t('search.tooltip')}
        />
        <NumberField
          icon={<Icons.People />}
          label={t('search.rooms')}
          name="rooms"
        />
      </div>
      <button className="berg-components-search__button">
        {t('search.submit')}
      </button>
    </form>
  );
};

export default Search;
