import { useEffect, useState } from 'react';
import './IndicatorCarousel.scss';

export interface IIndicatorCarouselProps {
  children: JSX.Element[];
}

let isPaused = false;

const IndicatorCarousel = (props: IIndicatorCarouselProps) => {
  const { children } = props;

  const [activeItem, setActiveItem] = useState<number>(0);

  const onPause = () => {
    isPaused = true;
  };

  const onResume = () => {
    isPaused = false;
  };

  const handleItemChange = (index: number) => {
    setActiveItem(index);
  };

  useEffect(() => {
    const switchItem = () => {
      if (isPaused) {
        return;
      }

      setActiveItem((prev) => {
        if (prev < children?.length - 1) {
          return prev + 1;
        } else {
          return 0;
        }
      });
    };

    const intervalIndex = setInterval(switchItem, 5000);

    return () => {
      clearInterval(intervalIndex);
    };
  }, [children?.length]);

  if (!children?.length) {
    return null;
  }

  return (
    <div
      className="berg-components-indicator-carousel"
      onMouseOver={onPause}
      onMouseLeave={onResume}
    >
      {children.map((child: JSX.Element, index: number) => (
        <div key={index} style={{ opacity: index === activeItem ? 1 : 0 }}>
          {child}
        </div>
      ))}
      <div className="berg-components-indicator-carousel__dots">
        {children.map((_, index) => (
          <button
            key={index}
            data-is-active={index === activeItem}
            onClick={() => handleItemChange(index)}
          >
            <span />
          </button>
        ))}
      </div>
    </div>
  );
};

export default IndicatorCarousel;
