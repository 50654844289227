import Utils from '../../utils';

const Icon = ({ icon }: { icon: string }) => {
  const filePath = () => {
    try {
      return require(`../../assets/icons/accommodation/${icon}.svg`);
    } catch {
      return require('../../assets/icons/accommodation/default.svg');
    }
  };

  return <img src={Utils.maybeDefault(filePath())} />;
};

export default Icon;
