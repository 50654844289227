import React from 'react';
import Components from '..';
import Pictures from '../../assets/picture';
import './Dialog.scss';

interface IProps {
  isOpen: boolean;
  children: React.ReactNode;
  handleClose: (param?: any) => void;
  buttonClassName?: string;
  dialogClassName?: string;
}

const Dialog = (props: IProps) => {
  const { isOpen, children, handleClose, buttonClassName, dialogClassName } =
    props;

  if (!isOpen) {
    return null;
  }

  return (
    <Components.Portal id="dialog">
      <div className="berg-components-dialog-backdrop" onClick={handleClose} />
      <div className={['berg-components-dialog', dialogClassName].join(' ')}>
        <button
          type="button"
          onClick={handleClose}
          className={[
            'berg-components-dialog__close-btn',
            buttonClassName,
          ].join(' ')}
        >
          <img src={Pictures.Close} alt="close" />
        </button>
        <div className="berg-components-dialog__content">{children}</div>
      </div>
    </Components.Portal>
  );
};

export default Dialog;
