import moment from 'moment';
import { ISeasonPeriod } from '../../../modules/location/details/models/locationDetails';
import isWithinDatesRange from '../../../utils/isWithinDatesRange';

interface IProps {
  tabs: ISeasonPeriod[];
  date: string | null;
  isVisible: boolean;
}

const getDateDisplay = (month: number, day: number) =>
  moment({ month: month - 1, day }).format('DD MMMM');

const SeasonTabs = (props: IProps) => {
  const { tabs, isVisible, date } = props;

  if (!isVisible) {
    return null;
  }

  const formattedTabs = tabs?.map((tab) => {
    const description = `${getDateDisplay(
      tab.from.month,
      tab.from.day
    )} - ${getDateDisplay(tab.to.month, tab.to.day)}`;

    const isWithinRange = isWithinDatesRange(tab.from, tab.to, moment(date));

    return {
      title: tab.seasonType,
      description,
      isWithinRange,
      priority: tab.priority,
    };
  });

  const highestPriorityTabWithinRange = formattedTabs
    .filter((tab) => tab.isWithinRange)
    .reduce((maxPriorityTab, currentTab) => {
      if (currentTab.priority > maxPriorityTab.priority) {
        return currentTab;
      } else {
        return maxPriorityTab;
      }
    });

  const tabsWithPickedCurrentSeason = formattedTabs.map((tab) => {
    const isActive = tab.title === highestPriorityTabWithinRange.title;

    return { title: tab.title, description: tab.description, isActive };
  });

  return (
    <div className="seasons-tabs">
      {tabsWithPickedCurrentSeason.map((tab, index) => {
        const className = `season-tab${
          tab.isActive ? ' season-tab--active' : ''
        }`;

        return (
          <div key={index} className={className}>
            <div className="season-tab__title">{tab.title}</div>
            <div className="season-tab__description">{tab.description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SeasonTabs;
