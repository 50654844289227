import { useTranslation } from 'react-i18next';

type TPriceDisplay = (number: number | string) => string;

const usePriceDisplay = (
  currency: string
): { getPriceDisplay: TPriceDisplay } => {
  const { i18n } = useTranslation();

  const getPriceDisplay: TPriceDisplay = (number) => {
    try {
      return (+number).toLocaleString(i18n.resolvedLanguage, {
        style: 'currency',
        // currency,
      });
    } catch {
      return `${number} ${currency ?? ''}`;
    }
  };

  return { getPriceDisplay };
};

export default usePriceDisplay;
