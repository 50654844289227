import { CSSProperties } from 'react';
import './BergGridWrapper.scss';

export interface BergGridWrapperProps
  extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
  py?: number;
  my?: number;
  ml?: number;
}
const BergGridWrapper = ({
  children,
  py,
  my,
  ml,
  ...props
}: BergGridWrapperProps) => {
  const style: CSSProperties = {
    paddingTop: `${0.5 * (py ?? 1)}rem`,
    paddingBottom: `${0.5 * (py ?? 1)}rem`,
    marginTop: `${0.5 * (my ?? 1)}rem`,
    marginBottom: `${0.5 * (my ?? 1)}rem`,
    marginLeft: `${0.5 * (ml ?? 0)}rem`,
  };
  return (
    <div className="berg-grid-wrapper" style={style} {...props}>
      {children}
    </div>
  );
};

export default BergGridWrapper;
