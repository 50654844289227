import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import snackbar from '../../utils/snackbar';
import { LayoutStateModel } from './models';

const initialState = {
  loading: false,
} as LayoutStateModel;

const layoutSlice = createSlice({
  name: 'Layout',
  initialState: initialState,
  reducers: {
    showLoader: (state) => {
      state.loading = true;
    },
    hideLoader: (state) => {
      state.loading = false;
    },
    hideLoaderFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      snackbar({ type: 'error', msg: action.payload });
    },
  },
});

export const { showLoader, hideLoader, hideLoaderFailure } =
  layoutSlice.actions;
export default layoutSlice.reducer;
