import moment, { Moment } from "moment";

import Day from "./Day";

interface IDaysProps {
  date: Moment;
  today: Moment;
}

const Days = (props: IDaysProps) => {
  const { date, today } = props;

  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate =
    moment(date).startOf("month").day() === 0
      ? 7
      : moment(date).startOf("month").day();
  const previousMonth = moment(date).subtract(1, "month");
  const previousMonthDays = previousMonth.daysInMonth();
  const nextMonth = moment(date).add(1, "month");
  const firstLayerBg = [];
  const days = [];
  const labels = [];

  for (let i = 1; i <= 7; i++) {
    labels.push(
      <span key={i} className="label">
        {today.clone().day(i).format("ddd")}
      </span>
    );
  }

  for (let i = firstDayDate; i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);

    firstLayerBg.push(
      <Day
        key={moment(previousMonth).format("DD MM YYYY")}
        date={moment(previousMonth)}
      />
    );

    days.push(
      <Day
        key={moment(previousMonth).format("DD MM YYYY")}
        date={moment(previousMonth)}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    firstLayerBg.push(
      <Day
        key={moment(thisDate).format("DD MM YYYY")}
        date={moment(thisDate)}
      />
    );

    days.push(
      <Day
        key={moment(thisDate).format("DD MM YYYY")}
        date={moment(thisDate)}
      />
    );
  }

  const daysCount = days.length;

  for (let i = 1; i <= 42 - daysCount; i++) {
    nextMonth.date(i);

    firstLayerBg.push(
      <Day
        key={moment(nextMonth).format("DD MM YYYY")}
        date={moment(nextMonth)}
      />
    );

    days.push(
      <Day
        key={moment(nextMonth).format("DD MM YYYY")}
        date={moment(nextMonth)}
      />
    );
  }

  return (
    <div className="single-calendar-days">
      {labels.concat()}
      <div className="single-calendar-days__days">
        <div className="single-calendar-days__bg-layer">
          {firstLayerBg.concat()}
        </div>
        <div className="single-calendar-days__numbers-layer">
          {days.concat()}
        </div>
      </div>
    </div>
  );
};

export default Days;
