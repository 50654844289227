import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import './Dropdown.scss';

import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { IMenuItem } from '../types';
import Hooks from '../../../hooks';

interface IProps {
  item: IMenuItem;
  onItemClick?: () => void;
}

const Dropdown = (props: IProps) => {
  const { item, onItemClick } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const triggerDropdown = () => setIsExpanded((prev) => !prev);

  const handleItemClick = () => {
    triggerDropdown();
    onItemClick?.();
  };

  const dropdownRef = Hooks.useOutsideClick({
    shouldWatch: isExpanded,
    callback: triggerDropdown,
  });

  const listClassName = `berg-components-navigation-dropdown-list ${
    isExpanded ? 'visible' : ''
  }`;

  return (
    <div
      className="berg-components-navigation-dropdown"
      aria-expanded={isExpanded ? 'true' : 'false'}
      ref={dropdownRef}
    >
      <button type="button" onClick={triggerDropdown}>
        {item.title}
        {isExpanded ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      <ul className={listClassName}>
        {item.submenu?.map((submenu: any, index: number) => (
          <li key={index} onClick={handleItemClick}>
            <Link to={submenu.url}>{submenu.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
