import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IAnnouncementsModel,
  IAnnouncementsResponseState,
} from './announcements.model';
import { fetchAnnouncements } from './operations';

const initialState = {} as IAnnouncementsModel;

const announcementsSlice = createSlice({
  name: 'Announcements',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchAnnouncements.fulfilled,
      (state, action: PayloadAction<IAnnouncementsResponseState>) => {
        state.data = action.payload.response;
      }
    );
  },
});

export default announcementsSlice.reducer;
