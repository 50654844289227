import Components from '..';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDropdownOption } from '../Dropdown/Dropdown';
import Hooks from '../../hooks';

interface IProps {
  icon: any;
  label: string;
  name: 'adults' | 'children' | 'rooms';
  tooltip?: string;
}

const NumberField = (props: IProps) => {
  const { icon, label, name, tooltip } = props;
  const { t } = useTranslation('components');

  const [isExpanded, setIsExpanded] = useState(false);
  const [count, setCount] = useState(0);

  const triggerDropdown = () => setIsExpanded((prev) => !prev);

  const onSave = (value: number) => {
    setCount(value);
    triggerDropdown();
  };

  const dropdownRef = Hooks.useOutsideClick({
    shouldWatch: isExpanded,
    callback: triggerDropdown,
  });

  const listClassName = `search-number-field__choice-number-persons${
    isExpanded ? ' search-number-field__choice-number-persons--visible' : ''
  }`;

  const labelClassName = `search-number-field__label${
    count > 0 ? ' search-number-field__label--active' : ''
  }`;

  const yearsChildren = [
    { value: '0', label: t('search.less_than_one_year') },
    { value: '1', label: t('search.one_year') },
    { value: '2', label: t('search.two_years') },
    { value: '3', label: t('search.three_years') },
    { value: '4', label: t('search.four_years') },
  ] as IDropdownOption[];

  for (let i = 5; i < 17; i++) {
    yearsChildren.push({
      value: i.toString(),
      label: `${i}  ${t('search.years')}`,
    });
  }

  return (
    <div className="search-number-field" ref={dropdownRef}>
      <div className="search-number-field__icon">
        <Components.Tooltip content={tooltip} />
        {icon}
      </div>
      <button
        className="search-number-field__caret"
        type="button"
        onClick={triggerDropdown}
        aria-expanded={isExpanded ? 'true' : 'false'}
      >
        <label htmlFor={name} className={labelClassName}>
          <div>{count}</div>
          <div>{label}</div>
        </label>
        {isExpanded ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      <div className={listClassName}>
        <Components.ChoiceNumberChildren
          name={name}
          count={count}
          onSave={onSave}
          isExpanded={isExpanded}
        />
        <Components.ChoiceNumberPersons
          isExpanded={isExpanded}
          count={count}
          onSave={onSave}
          name={name}
        />
      </div>
    </div>
  );
};

export default NumberField;
