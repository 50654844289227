/* eslint-disable complexity */
import { ChangeEvent, useState } from 'react';
import { IDataCarrierIdInput, IPickedTickets } from '../types';
import Components from '../..';
import Pictures from '../../../assets/picture';
import { useTranslation } from 'react-i18next';
import { IBasketItem } from '../../../modules/basket/models';
import Icons from '../../../assets/icons';

interface IProps {
  ticket: IDataCarrierIdInput;
  index: number;
  inputsState: IDataCarrierIdInput[];
  basketItems: IBasketItem[];
  setInputsState: React.Dispatch<React.SetStateAction<IDataCarrierIdInput[]>>;
  setPickedTickets: React.Dispatch<React.SetStateAction<IPickedTickets[]>>;
}

const Input = (props: IProps) => {
  const { ticket, setInputsState, index, inputsState, basketItems } = props;
  const { t } = useTranslation('components');

  const [value, setValue] = useState<string>(ticket.dataCarrierId ?? '');

  const updateInputsState = (param: {
    [key: string]: boolean | string | null;
  }) => {
    setInputsState((prev) =>
      prev.map((input) => {
        if (input.uniqueId === ticket.uniqueId) {
          return { ...input, ...param };
        } else return input;
      })
    );
  };

  const setDataCarrierId = (dataCarrierId: string) => {
    updateInputsState({ dataCarrierId });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    const dataCarrierId = value;
    setDataCarrierId(dataCarrierId);
  };

  const dateInput = inputsState.map((x: IDataCarrierIdInput) => x && x?.date);

  const idBasket = basketItems.find((order: IBasketItem) =>
    order.licencePlates?.includes(value)
  );

  const dateBasket = basketItems.find((order: IBasketItem, index: number) =>
    order.date?.includes(dateInput[index] || '')
  );

  let classValid = '';

  const foundRecurringValues = inputsState.map(
    (ticket: IDataCarrierIdInput, index: number) =>
      inputsState.some(
        (otherTicket: IDataCarrierIdInput, otherIndex: number) => {
          if (index !== otherIndex) {
            return ticket.dataCarrierId === otherTicket.dataCarrierId;
          }
        }
      )
  );

  if (foundRecurringValues.includes(true)) {
    classValid = 'invalid';
  } else {
    classValid = 'valid';
  }

  return (
    <>
      <label htmlFor={ticket.uniqueId}>
        {ticket.name} {index + 1}
      </label>
      <div className="berg-components-media-data-aside">
        {dateBasket && idBasket ? (
          <div className="berg-components-media-data-aside__error_unique_order_mes">
            <input
              name={ticket.uniqueId}
              type="text"
              id={ticket.uniqueId}
              placeholder={
                ticket.dataCarrierId ? ticket.dataCarrierId : 'TS802FR'
              }
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              minLength={2}
              maxLength={20}
            />

            <Components.ValidationMessage
              color="error"
              // eslint-disable-next-line react/no-children-prop
              children={<Icons.AlertWhite />}
              content={t('media_data.error_unique_order_mes')}
            ></Components.ValidationMessage>
          </div>
        ) : (
          <input
            name={ticket.uniqueId}
            type="text"
            id={ticket.uniqueId}
            placeholder={
              ticket.dataCarrierId ? ticket.dataCarrierId : 'TS802FR'
            }
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            minLength={2}
            maxLength={20}
          />
        )}

        {value && value.length > 1 && !idBasket && classValid === 'valid' && (
          <img
            src={Pictures.Check}
            alt="check"
            className="berg-components-media-data-img"
          />
        )}
        {value && value.length <= 1 && classValid === 'invalid' && (
          <div className="error">
            <Components.Tooltip content={t('media_data.error_mes')}>
              {
                <img
                  src={Pictures.Error}
                  alt="error"
                  className="berg-components-media-data-img"
                />
              }
            </Components.Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
