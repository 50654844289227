import moment, { Moment } from 'moment';

interface IDayProps {
  currentDate?: Moment;
  date: Moment;
  startDate?: Moment | null;
  endDate?: Moment | null;
  onClick?: (date: Moment) => void;
  onHover?: (date: Moment) => void;
}

const Day = (props: IDayProps) => {
  const { currentDate, date, startDate, endDate, onClick, onHover } = props;

  const className = [];

  if (moment().isSame(date, 'day')) {
    className.push('active');
  }

  if (date.isSame(startDate, 'day')) {
    className.push('start');
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }

  if (date.isBefore(moment(), 'day')) {
    className.push('disabled');
  }

  const isDisabled = date.isBefore(moment(), 'day');

  return (
    <span
      onClick={onClick && !isDisabled ? () => onClick(date) : undefined}
      onMouseOver={onHover && !isDisabled ? () => onHover(date) : undefined}
      data-current-date={date}
      className={className.join(' ')}
    >
      {date.date()}
    </span>
  );
};

export default Day;
