import moment, { Moment } from 'moment';
import React, { createContext } from 'react';
import { useState } from 'react';
import './SingleCalendar.scss';
import Days from './Days';
import Heading from './Heading';
import { IRange } from '../../modules/location/details/models/locationDetails';

interface ISingleCalendarProps {
  dateChoice: string | null;
  firstRangeStart?: IRange;
  firstRangeEnd?: IRange;
  secondRangeStart?: IRange;
  secondRangeEnd?: IRange;
  onDateChange?: (date: string) => void;
}

interface IDaysContext {
  today: Moment;
  selectedMonth: Moment | null;
  dateChoice: Moment | null;
  firstRangeStart: IRange | null;
  firstRangeEnd: IRange | null;
  secondRangeStart: IRange | null;
  secondRangeEnd: IRange | null;
  onClick: (date: string) => void;
}

export const DaysContext = createContext<Partial<IDaysContext>>({});

const SingleCalendar = (props: ISingleCalendarProps) => {
  const {
    firstRangeStart,
    firstRangeEnd,
    secondRangeStart,
    secondRangeEnd,
    onDateChange,
  } = props;

  const today = moment();

  const [date, setDate] = useState<Moment>(today);
  const [dateChoice, setDateChoice] = useState<string | null>(props.dateChoice);

  const resetDate = () => {
    setDate(today);
  };

  const changeMonth = (month: number) => {
    setDate((prev) => prev.clone().month(month));
  };

  const changeDate = (date: string) => {
    setDateChoice(date);
    onDateChange?.(moment(date).toISOString());
  };

  return (
    <div className="berg-components-single-calendar">
      <Heading
        date={date}
        changeMonth={changeMonth}
        resetDate={() => resetDate()}
      />
      <DaysContext.Provider
        value={{
          today,
          selectedMonth: date,
          dateChoice: dateChoice ? moment(dateChoice) : null,
          firstRangeStart,
          firstRangeEnd,
          secondRangeStart,
          secondRangeEnd,
          onClick: changeDate,
        }}
      >
        <Days date={date} today={today} />
      </DaysContext.Provider>
    </div>
  );
};

export default SingleCalendar;
