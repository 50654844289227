import moment, { Moment } from 'moment';
import React from 'react';
import { useState } from 'react';
import './RangeCalendar.scss';
import Days from './Days';
import Heading from './Heading';
import Icons from '../../assets/icons';
import { useTranslation } from 'react-i18next';

interface ICalendarProps {
  name: string;
  startDate?: string;
  endDate?: string;
  onDatesChange?: (dates: [string, string]) => void;
  disabled?: boolean;
  duration?: string;
}

const Calendar = (props: ICalendarProps) => {
  const { onDatesChange, disabled = false, name } = props;
  const { t } = useTranslation('common');

  const [date, setDate] = useState<Moment>(
    props.startDate ? moment(props.startDate) : moment()
  );
  const [startDate, setStartDate] = useState<Moment | null>(
    props.startDate ? moment(props.startDate) : null
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    props.endDate ? moment(props.endDate) : null
  );

  const [endDatePreview, setEndDatePreview] = useState<Moment | null>(null);

  const [alert, setAlert] = useState<boolean>(false);
  const handleChoose2night = () => {
    setAlert((value) => !value);
  };

  const resetDate = () => {
    setDate(moment());
  };

  const changeMonth = (month: number) => {
    setDate((prev: Moment) => prev.clone().month(month));
  };

  const changeYear = (year: number) => {
    setDate((prev: Moment) => prev.clone().year(year));
  };

  const changeDate = (date: Moment) => {
    if (
      startDate === null ||
      date.isBefore(startDate, 'day') ||
      endDate !== null
    ) {
      setStartDate(moment(date));
      setEndDate(null);
      console.log(startDate);
    } else if (
      // date.isAfter(startDate, 'day') &&
      date.isAfter(startDate.add(1, 'day'), 'day')
    ) {
      setEndDate(moment(date));
      setStartDate(startDate.subtract(1, 'day'));
      console.log(startDate);
      console.log(endDate);
      onDatesChange?.([
        startDate?.format('YYYY-MM-DD'),
        moment(date)?.format('YYYY-MM-DD'),
      ]);
    } else if (!date.isAfter(startDate.add(1, 'day'), 'day')) {
      handleChoose2night();
      setStartDate(null);
    }
  };

  const previewRange = (date: Moment) => {
    if (endDate && startDate) {
      return;
    }

    if (startDate !== null && date.isAfter(startDate, 'day')) {
      setEndDatePreview(moment(date));
    } else {
      setEndDatePreview(null);
    }
  };

  const onMouseLeave = () => {
    setEndDatePreview(null);
  };

  return (
    <div className="berg-components-calendar">
      <Heading
        date={date}
        changeMonth={changeMonth}
        resetDate={resetDate}
        changeYear={changeYear}
      />
      <Days
        onMouseLeave={onMouseLeave}
        onClick={changeDate}
        onHover={previewRange}
        isDisabled={disabled}
        date={date}
        startDate={startDate}
        endDate={endDatePreview ?? endDate}
      />
      <input
        type="text"
        name={`${name}_start_date`}
        value={startDate?.format('YYYY-MM-DD') ?? ''}
        readOnly
      />
      <input
        type="text"
        name={`${name}_end_date`}
        value={endDate?.format('YYYY-MM-DD') ?? ''}
        readOnly
      />
      {alert && (
        <div className="berg-components-calendar__error">
          <Icons.AlertAttentionRed />
          {t('minimum_2_nights')}
        </div>
      )}
    </div>
  );
};

export default Calendar;
