import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ParkingMediaData.scss';
import { IDataCarrierIdInput, IPickedTickets } from '../types';
import Input from './Input';
import { getBasketItems } from '../../../modules/basket/selectors';
import Hooks from '../../../hooks';
import Icons from '../../../assets/icons';

interface IProps {
  tickets: IPickedTickets[];
  hide: () => void;
  setPickedTickets: Dispatch<SetStateAction<IPickedTickets[]>>;
}

const ParkingMediaData = (props: IProps) => {
  const { tickets, hide, setPickedTickets } = props;

  const { t } = useTranslation('components');

  const basketItems = Hooks.useAppSelector((s) => getBasketItems(s.basket));

  const initialInputsState = tickets.map((ticket) => ({
    ...ticket,
    value: ticket.dataCarrierId,
  }));

  const [inputsState, setInputsState] =
    useState<IDataCarrierIdInput[]>(initialInputsState);

  const onSave = () => {
    const setDataCarriersIds = (input: IDataCarrierIdInput) => {
      const assignDataCarrierIds = (ticket: IPickedTickets) => {
        if (ticket.uniqueId === input.uniqueId) {
          return { ...ticket, dataCarrierId: input.dataCarrierId };
        } else {
          return ticket;
        }
      };

      setPickedTickets((prev) => prev.map(assignDataCarrierIds));
    };

    inputsState.forEach(setDataCarriersIds);

    hide();
  };

  const dateInput = inputsState.find(
    (x: IDataCarrierIdInput) => x && x?.dataCarrierId
  );

  let classValid = '';

  const foundRecurringValues = inputsState.map(
    (ticket: IDataCarrierIdInput, index: number) =>
      inputsState.some(
        (otherTicket: IDataCarrierIdInput, otherIndex: number) => {
          if (index !== otherIndex) {
            return ticket.dataCarrierId === otherTicket.dataCarrierId;
          }
        }
      )
  );

  if (foundRecurringValues.includes(true)) {
    classValid = 'invalid';
  } else {
    classValid = 'valid';
  }

  return (
    <div className="berg-components-media-data">
      <div className="berg-components-media-data-header">
        <h1 className="berg-components-media-data-header-text">
          {t('media_data.header_car')}
        </h1>
      </div>
      <div className="berg-components-media-data-form">
        {inputsState.map((ticket: any, index: number) => (
          <Input
            ticket={ticket}
            index={index}
            key={ticket.uniqueId}
            setInputsState={setInputsState}
            setPickedTickets={setPickedTickets}
            inputsState={inputsState}
            basketItems={basketItems}
          />
        ))}
      </div>

      <div className="berg-components-media-data-footer">
        <hr />
        {classValid === 'invalid' && dateInput !== undefined ? (
          <>
            <button type="button" onClick={onSave} disabled={true}>
              {t('media_data.submit')}
            </button>
            <div className="berg-components-media-data-footer__validation-error">
              <Icons.AlertAttention />
              {t('media_data.validation-error')}
            </div>
          </>
        ) : (
          <button type="button" onClick={onSave}>
            {t('media_data.submit')}
          </button>
        )}
      </div>
    </div>
  );
};

export default ParkingMediaData;
