import { useTranslation } from 'react-i18next';
import Components from '../..';
import {
  CompanyData,
  ContactData,
} from '../../../modules/common/models/orderData';
import './OrderContactData.scss';
interface IProps {
  contactData: ContactData | undefined;
  companyData?: CompanyData | undefined;
}
const OrderSummaryHeader = ({ contactData, companyData }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Components.BergStack direction="row" spacing={10}>
      {contactData && (
        <div className="order-contact-data">
          <p className="order-contact-data__label">
            {t('table_label.contact_data')}
          </p>
          <div className="order-contact-data__text">
            <div className="order-contact-data__text">
              <p>
                <span>
                  {contactData?.name} {contactData?.lastName}
                </span>
              </p>
            </div>

            <div className="order-contact-data__text">
              <p>
                {contactData?.street} {contactData?.houseNumber}
              </p>
              <p>
                {contactData?.postalCode} {contactData?.city}
              </p>
            </div>
            <div className="order-contact-data__text">
              <p>{contactData?.mail}</p>
              {contactData?.phone && <p>{contactData?.phone}</p>}
            </div>
          </div>
        </div>
      )}
      {companyData && (
        <div className="order-contact-data">
          <p className="order-contact-data__label">
            {t('table_label.company_data')}
          </p>
          <div className="order-contact-data__text">
            <div className="order-contact-data__text">
              <p>
                <span>{companyData?.name}</span>
              </p>
            </div>

            <div className="order-contact-data__text">
              <p>{companyData?.nip}</p>
              <p>
                {companyData?.street} {companyData?.houseNumber}
              </p>
              <p>
                {companyData?.postalCode} {companyData?.city}
              </p>
            </div>
          </div>
        </div>
      )}
    </Components.BergStack>
  );
};

export default OrderSummaryHeader;
