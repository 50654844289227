import { CompanyData, ContactData } from '../common/models/orderData';
import { BasketItemType, IBasketItem, IBasketStateModel } from './models';

export const getBasketItems = (state: IBasketStateModel) => state.items;

export const getBasketTotalQuantity = (state: IBasketStateModel): number =>
  state.items.reduce((acc, curr) => acc + curr.quantity, 0);

export const getBasketItemsByType = (
  state: IBasketStateModel,
  type: BasketItemType
): IBasketItem[] => state.items?.filter((x) => x.itemType === type);

export const getBasketSkiPasses = (state: IBasketStateModel) =>
  state.items?.filter((x) => x.itemType === BasketItemType.SkiPass);

export const getBasketTotal = (state: IBasketStateModel) => state.totalPrice;
export const getBasketCurrency = (state: IBasketStateModel) =>
  state.basketCurrency;

export const getBasketTotalAtLocation = (state: IBasketStateModel) =>
  state.totalPriceAtLocation;

export const getBasketContactData = (
  state: IBasketStateModel
): ContactData | undefined => state.contactData;

export const getBasketCompanyData = (
  state: IBasketStateModel
): CompanyData | undefined => state.companyData;

export const isQuickOrder = (state: IBasketStateModel): boolean =>
  state.items.findIndex((x) => x.item.requirePersonalData === true) === -1;

export const getPopups = (state: IBasketStateModel): boolean =>
  state.showPopups;
export const getLastItemId = (state: IBasketStateModel): string =>
  state.lastItemId;

export const hasOnlySkiPass = (state: IBasketStateModel): boolean =>
  state.items.findIndex((x) => x.itemType === BasketItemType.SkiPass) >= 0 &&
  state.items.findIndex((x) => x.itemType !== BasketItemType.SkiPass) === -1;
