import React from 'react';
import './Status404.scss';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Status404 = () => {
  const { t } = useTranslation('pages');
  return (
    <>
      <img
        className="berg-page-404-picture"
        src={require('../../assets/picture/dluga-polana-logo.png')}
        alt="error 404"
      />
      <p className="berg-page-404-text">{t('status404.text')}</p>
      <button className="berg-page-404-button">
        <Link to={'/parking'}>{t('status404.go_to_home_page')}</Link>
      </button>
    </>
  );
};

export default Status404;
