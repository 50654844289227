export enum ProductCategory {
  SkiPass = 'skiPass',
  SkiRental = 'skiRental',
  SkiSchool = 'skiSchool',
  Therms = 'therms'
}

export enum PickupMethod {
  SkiPassMachine = 'skiPassMachine',
  OnSite = 'onSite',
}

export enum PaymentMethod {
  OnLine = 'online',
  OnSite = 'onSite',
}

export enum WarningType {
  Warning = 'WARNING',
  Informational = 'INFORMATIONAL',
}
