import { PickupMethod } from '../common/models/enums';
import { CompanyData, ContactData } from '../common/models/orderData';
import { IProduct } from '../common/models/productModels';

export enum BasketItemType {
  SkiPass = 'SkiPass',
  Accommodation = 'Accommodation',
  Parking = 'Parking',
}

export interface IBasketStateModel {
  totalPrice: number;
  totalPriceAtLocation: number;
  items: IBasketItem[];
  companyData?: CompanyData;
  contactData?: ContactData;
  basketCurrency: string;
  showPopups: boolean;
  lastItemId: string;
}

export interface IBasketAdditionalInformation {
  type: string;
  value: string;
  name: string;
  unit: string;
}

export interface IBasketItem {
  id: string;
  quantity: number;
  price: number;
  currency: string;
  consumerCategoryId: string;
  itemType: BasketItemType;
  item: IProduct;
  additionalInformation: IBasketAdditionalInformation[];
  pickupMethod?: PickupMethod;
  validityDate?: string;
  date?: string;
  startDate?: string;
  endDate?: string;
  daysCount?: number;
  additionalGuestsCount?: number;
  additionalGuestPrice?: number;
  licencePlates?: string[];
}

export interface ISkiPassBasketItem extends IBasketItem {
  dataCarrierIds: string[];
  validityDate: string;
}

export interface IParkingBasketItem extends IBasketItem {
  date: string;
  licencePlates?: string[];
}

export interface IAccommodationBasketItem extends IBasketItem {
  childrenAges: number[];
  adultCount: number;
  startDate: string;
  endDate: string;
}

export interface IQuantityOperation {
  basketId: string;
  changeValueBy: number;
}
