"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageGuard = void 0;
class StorageGuard {
    constructor(api, device, options) {
        this.api = api;
        this.device = device;
        this.options = options;
        this.deviceForceEnabled = false;
        this.isAppValid = true;
        this.isDeviceEnabled = false;
        const checkIsDeviceEnabledIntervalMs = 5 * 60 * 1000;
        this.checkIsDeviceEnabled();
        setInterval(this.checkIsDeviceEnabled.bind(this), checkIsDeviceEnabledIntervalMs);
    }
    canProcess() {
        return this.isAppValid && (this.isDeviceEnabled || this.deviceForceEnabled);
    }
    canWrite() {
        return this.isAppValid;
    }
    checkIsDeviceEnabled() {
        const deviceInfo = this.device.getInfo(this.options);
        return this.api.getDeviceStatus(deviceInfo).then((status) => {
            this.isAppValid = status.isAppValid;
            this.isDeviceEnabled = status.isDeviceEnabled;
        }, () => {
        });
    }
    setDeviceForceEnabled(state) {
        this.deviceForceEnabled = state;
    }
}
exports.StorageGuard = StorageGuard;
