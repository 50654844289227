import { NavLink, Outlet } from "react-router-dom";
import "./Tabs.scss";

interface IPage {
  path: string;
  title: string;
  element: JSX.Element;
}

interface IProps {
  pages: IPage[];
}

const Tabs = (props: IProps) => {
  const { pages } = props;

  return (
    <section className="berg-components-tabs">
      <div className="tabs-buttons">
        {pages.map((link: { title: string; path: string }, index) => (
          <NavLink key={index} to={link.path} className="tabs-button">
            {link.title}
          </NavLink>
        ))}
      </div>
      <Outlet />
    </section>
  );
};

export default Tabs;
