export enum Season {
  Winter = 'winter',
  Summer = 'summer',
}

export enum TicketPrefills {
  Today = 'today',
}

export enum LocalStorageKeys {
  CookiesConsent = 'cookiesConsent',
  billboardFirstOccurrenceTime = 'billboardFirstOccurrenceTime',
  isBillboardDismissed = 'isBillboardDismissed',
}
