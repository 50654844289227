import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../../components';
import Hooks from '../../hooks';
import Calendar from './Calendar';
import { useSearchParams } from 'react-router-dom';
import { TicketPrefills } from '../../config/globalVariables';
import { getParkingDefaults } from '../../modules/location/settings/selectors';
import snackbar from '../../utils/snackbar';
import {
  getParkingEntries,
  getParkingTypes,
} from '../../modules/parking/selectors';
import {
  fetchParking,
  fetchParkingTypes,
} from '../../modules/parking/operations';
import { fetchParkingDetails } from '../../modules/location/details/operations';
import { resetPickedParkingPasses } from '../../modules/parking/slice';
import Pictures from '../../assets/picture';

const Parking = () => {
  const { t } = useTranslation('pages');

  const [date, setDate] = useState<string | null>(null);
  const [typeId, setTypeId] = useState<string>();

  const dispatch = Hooks.useAppDispatch();
  const numberOfPersons = Hooks.useAppSelector((s) =>
    getParkingTypes(s.parking)
  );
  const parkingPasses = Hooks.useAppSelector((s) =>
    getParkingEntries(s.parking)
  );

  const parkingTypes = Hooks.useAppSelector((s) => getParkingTypes(s.parking));

  const [params] = useSearchParams();
  const prefill = params.get('prefill');

  const defaults = Hooks.useAppSelector((s) => getParkingDefaults(s.settings));

  const parkingPassesContent = useMemo(
    () =>
      parkingPasses?.map((ticket) => ({
        ...ticket,
        isHighlighted: true,
      })),
    [parkingPasses]
  );

  const typeDropdown = numberOfPersons?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleTypeChange = (id: string) => {
    setTypeId(id);
  };

  const resetDate = () => {
    const now = moment();

    if (
      now.isBetween(
        moment(defaults?.maximumBuyingTimeForToday, 'hh:mm:ss'),
        moment('23:59:59', 'hh:mm:ss')
      )
    ) {
      setDate(now.add(1, 'days').format());
      snackbar({
        type: 'warning',
        msg: t('activities.tickets.nextDayWarning'),
      });
    } else {
      setDate(moment().toISOString());
    }
  };

  const tomorrowDate = () => {
    const now = moment();
    setDate(now.add(1, 'days').format());
  };

  const onDateChange = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    if (date) {
      dispatch(fetchParkingTypes());
      dispatch(fetchParkingDetails());
    }
  }, [date, dispatch]);

  useEffect(() => {
    if (date && typeId) {
      dispatch(fetchParking({ typeId, date }));
    }

    return () => {
      dispatch(resetPickedParkingPasses());
    };
  }, [date, dispatch, typeId]);

  useEffect(() => {
    const now = moment(defaults?.localDateTime);

    if (
      prefill === TicketPrefills.Today &&
      !!defaults?.maximumBuyingTimeForToday
    ) {
      if (
        now.isBefore(moment(defaults?.maximumBuyingTimeForToday, 'HH:mm:ss'))
      ) {
        setDate(now.format());
      } else {
        setDate(now.add(1, 'days').format());
        snackbar({
          type: 'warning',
          msg: t('activities.tickets.nextDayWarning'),
        });
      }
    }
  }, [
    defaults?.localDateTime,
    defaults?.maximumBuyingTimeForToday,
    prefill,
    t,
  ]);

  return (
    <>
      <div className="berg-pages-activities__parking">
        <div className="berg-pages-activities__parking--header">
          <img src={Pictures.LogoParking} alt="check" />
          <h2>{t('activities.parking')}</h2>
        </div>

        <hr />
        <Components.FilterBar
          defaultDropdownOptionValue={
            parkingTypes ? parkingTypes[0]?.id : defaults?.defaultTypeId
          }
          typeDropdown={date ? typeDropdown : undefined}
          label={t('activities.ticket_type')}
          Calendar={<Calendar date={date} onDateChange={onDateChange} />}
          onTypeChange={handleTypeChange}
          date={date}
          resetDate={resetDate}
          tomorrowDate={tomorrowDate}
        />
        <hr />
        <Components.ParkingTemplate parking={parkingPassesContent} />
      </div>
    </>
  );
};

export default Parking;
