import './BergDevider.scss';
interface BergDeviderProps extends React.ComponentPropsWithoutRef<'hr'> {
  color?: 'grey' | 'ligth-grey' | 'lighter-grey';
}
const BergDevider = ({ color }: BergDeviderProps) => {
  const classColor = `berg-devider__${color ?? 'grey'}`;
  return <hr className={['berg-devider', classColor].join(' ')}></hr>;
};

export default BergDevider;
