import { Trans, useTranslation } from 'react-i18next';
import './ContactDetails.scss';

const ContactDetails = () => {
  const { t } = useTranslation('components');

  return (
    <div className="berg-components-contact-details-wrapper">
      <div className="berg-components-contact-details">
        <div className="contact-details-content">
          <strong>{t('contact_details.post_office_address')}</strong>
          <b>Meander Thermal & Ski Resort</b>
          <span>
            Oravice 110
            <br />
            Tvrdošín
            <br />
            {t('contact_details.slovakia')}
          </span>

          <strong>{t('contact_details.hotline')}</strong>
          <a className="contact-details-bold-link" href="tel:+421435504601">
            +421 43 550 46 01
          </a>
          <a className="contact-details-bold-link" href="tel:+421905348828">
            +421 905 348 828
          </a>

          <Trans ns="components" i18nKey="contact_details.ticket_office">
            <strong>Kasa parku narciarskiego:</strong>
            <a className="contact-details-bold-link" href="tel:+421435504623">
              +421 43 550 46 23
            </a>
            <span>(9:00 - 16:00 - tylko w sezonie zimowym)</span>
          </Trans>

          <strong>{t('contact_details.email')}</strong>
          <a
            className="contact-details-bold-link"
            href="mailto:recepcia@meanderoravice.sk"
          >
            recepcia@meanderoravice.sk
          </a>

          <strong>{t('contact_details.rent')}</strong>
          <a className="contact-details-bold-link" href="tel:+421944612387">
            +421 944 612 387
          </a>
          <a
            className="contact-details-bold-link"
            href="mailto:rent@meanderoravice.sk"
          >
            rent@meanderoravice.sk
          </a>

          <strong>{t('contact_details.school')}</strong>
          <a className="contact-details-bold-link" href="tel:+421944612387">
            +421 944 612 387
          </a>
          <a
            className="contact-details-bold-link"
            href="mailto:school@meanderoravice.sk"
          >
            school@meanderoravice.sk
          </a>
        </div>
        <div className="contact-details-content">
          <strong>{t('contact_details.resort_address')}</strong>
          <b>Meander Thermal & Ski Resort</b>
          <span>
            Miestna časť Oravice 110
            <br />
            Tvrdošín
            <br />
            {t('contact_details.slovakia')}
          </span>
          <strong>{t('contact_details.headquarters_address')}</strong>
          <b>meander invest s.r.o.</b>
          <span>
            M.R Štefánika 1821
            <br />
            Dolný Kubín
            <br />
            {t('contact_details.slovakia')}
          </span>
          <span>
            <b>Company ID: </b>
            <span>44 820 313</span>
          </span>
          <span>
            <b>VAT ID: </b>
            <span>SK2022842404</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
