import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { IAccommodationSearchParams, TAccommodationModel } from './models';
import { calculateDaysCount, saveSearchParams } from './slice';

export const fetchAccommodations = createAsyncThunk(
  actionTypes.FETCH_ACCOMMODATIONS,
  async (
    params: IAccommodationSearchParams,
    thunkApi
  ): Promise<TAccommodationModel> => {
    thunkApi.dispatch(showLoader());

    try {
      const response = await axiosInstance.get<TAccommodationModel>(
        '/v1/accommodation',
        {
          params,
        }
      );

      thunkApi.dispatch(hideLoader());
      thunkApi.dispatch(
        calculateDaysCount({
          startDate: params.startDate,
          endDate: params.endDate,
        })
      );
      thunkApi.dispatch(saveSearchParams(params));
      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
