import React, { useContext, useEffect, useRef } from 'react';
import './Header.scss';
import Components from '../index';
import HeaderText from './HeaderText';
import { Trans } from 'react-i18next';
import { Season } from '../../config/globalVariables';
import { SeasonContext } from '../../context/SeasonContext';
import visibleOnRelease from '../../config/visibleOnRelease';

const Header = () => {
  const {
    setSeason,
    season: globalSeason,
    setIsSeasonSelectorTouched,
    isSeasonSelectorTouched,
  } = useContext(SeasonContext);

  const resizable = useRef<HTMLDivElement>(null);
  const draggable = useRef<HTMLDivElement>(null);
  const mouseXPosition = useRef<number>(0);

  const setStyles = (season: Season) => {
    if (!draggable.current || !resizable.current) {
      return;
    }

    if (season === Season.Summer) {
      draggable.current.style.left = '60px';
    } else if (season === Season.Winter) {
      draggable.current.style.left = `${window.innerWidth - 60}px`;
    }

    resizable.current.style.width = `${
      draggable.current.getBoundingClientRect().x + 40
    }px`;
  };

  const dragMouseDown = (e: any) => {
    e.preventDefault();
    mouseXPosition.current = e.clientX;

    document.onpointerup = closeDragElement;
    document.onpointermove = elementDrag;
  };

  const elementDrag = (e: any) => {
    e.preventDefault();

    const offsetLeft = mouseXPosition.current - e.clientX;
    mouseXPosition.current = e.clientX;

    if (!draggable.current || !resizable.current) {
      return;
    }

    const styleLeft = draggable.current.offsetLeft - offsetLeft;

    if (styleLeft <= 60 || styleLeft >= window.innerWidth - 60) {
      return;
    }

    draggable.current.style.left = `${styleLeft}px`;

    resizable.current.style.width = `${
      draggable.current.getBoundingClientRect().x + 40
    }px`;
  };

  const closeDragElement = () => {
    document.onpointerup = null;
    document.onpointermove = null;

    const halfViewportWidth = window.innerWidth / 2;

    if (!isSeasonSelectorTouched) {
      setIsSeasonSelectorTouched?.(true);
    }

    if (draggable.current && draggable.current.offsetLeft < halfViewportWidth) {
      setSeason?.(Season.Summer);
      setStyles(Season.Summer);
    } else {
      setSeason?.(Season.Winter);
      setStyles(Season.Winter);
    }
  };

  useEffect(() => {
    if (globalSeason && isSeasonSelectorTouched) {
      setStyles(globalSeason);
    }
  }, [globalSeason, isSeasonSelectorTouched]);

  return (
    <div className="berg-components-season-header-wrapper">
      <HeaderText type={Season.Summer}>
        <span className="season-hero-right-header">
          <Trans ns="components" i18nKey="header.summer"></Trans>
        </span>
      </HeaderText>
      <div className="berg-components-season-header" ref={resizable}>
        <div
          className="season-hero-cursor"
          onPointerDown={dragMouseDown}
          ref={draggable}
        />
        <div className="season-hero-left-image">
          <HeaderText type={Season.Winter}>
            <span className="season-hero-left-header">
              <Trans ns="components" i18nKey="header.winter"></Trans>
            </span>
          </HeaderText>
        </div>
      </div>
      {visibleOnRelease.showPromoBanner ? <Components.Banner /> : null}
      <div className="berg-components-season-header__alerts">
        {<Components.Alerts />}
      </div>
      {visibleOnRelease.mainSearch && <Components.Search />}
    </div>
  );
};

export default Header;
