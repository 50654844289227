import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../../../components';
import { FilterBarContext } from '../../../components/FilterBar/FilterBar';
import Hooks from '../../../hooks';
import { getSkiPassDetails } from '../../../modules/location/details/selectors';

interface IProps {
  onDateChange: (date: string) => void;
  date: string | null;
}

const Calendar = (props: IProps) => {
  const [date, setDate] = useState<string | null>(props.date);

  const { setIsCalendarVisible } = useContext(FilterBarContext);

  const skiPassDetails = Hooks.useAppSelector((s) =>
    getSkiPassDetails(s.location)
  );

  const firstRange = skiPassDetails.seasonPeriods.find(
    (period) => period.seasonType === 'top'
  );
  const secondRange = skiPassDetails.seasonPeriods.find(
    (period) => period.seasonType === 'high'
  );

  const onDateChange = (dateParam: string) => {
    setDate(dateParam);
    props.onDateChange(dateParam);
    setIsCalendarVisible?.(false);
  };

  const { t } = useTranslation(['pages', 'common']);

  return (
    <div className="activities-calendar">
      <div className="activities-calendar__middle-wrapper">
        <Components.SingleCalendar
          dateChoice={date}
          onDateChange={onDateChange}
          firstRangeStart={firstRange?.from}
          firstRangeEnd={firstRange?.to}
          secondRangeStart={secondRange?.from}
          secondRangeEnd={secondRange?.to}
        />
        <div className="activities-calendar__legend">
          <div>
            <span />
            {t('pages:activities.tickets.calendar_legend_1')}
          </div>
          <div>
            <span />
            {t('pages:activities.tickets.calendar_legend_2')}
          </div>
          <div>
            <span />
            {t('pages:activities.tickets.calendar_legend_3')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
