import { toast } from 'react-toastify';
import Icons from '../assets/icons';

interface IProps {
  type: 'success' | 'warning' | 'info' | 'error';
  msg: string;
  id?: number | string;
}

const snackbar = (props: IProps) => {
  const { type, msg, id: toastId } = props;

  switch (type) {
    case 'success':
      toast.success(msg, { icon: Icons.Tick, toastId });
      break;
    case 'info':
      toast.info(msg, { icon: Icons.AlertInfo, toastId });
      break;
    case 'warning':
      toast.warning(msg, { icon: Icons.Warning, toastId });
      break;
    case 'error':
      toast.error(msg, { icon: Icons.Warning, toastId });
      break;
  }
};

export default snackbar;
