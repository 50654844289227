import axiosInstance from '../../../services/axios';
import { IContactFormLp } from '../details/models/contact';
import { IContactForm, IContactResponse } from './contact.model';

export const sendContactForm = async (data: IContactForm): Promise<number> => {
  try {
    const response = await axiosInstance.post<IContactResponse>(
      '/v1/location/contact',
      data
    );

    return response.status;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};


export const sendContactFormLp = async (data: IContactFormLp): Promise<number> => {
  try {
    const response = await axiosInstance.post<IContactResponse>(
      '/v1/location/contact/landing-page',
      data
    );

    return response.status;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};
