import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder } from './models/details';
import { OrderStateModel } from './models/order';
import { fetchOrderDetails } from './operations';

const initialState = {} as OrderStateModel;

const orderSlice = createSlice({
  name: 'OrderSlice',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchOrderDetails.fulfilled,
      (state, action: PayloadAction<IOrder>) => {
        state.order = action.payload;
      }
    );
  },
});

export default orderSlice.reducer;
