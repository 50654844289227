import Components from '..';
import './ChoiceNumber.scss';
import { useTranslation } from 'react-i18next';
import ChildAgeSelect from './ChildAgeSelect';
import { useLayoutEffect, useReducer, useState } from 'react';
import Icons from '../../assets/icons';

interface IProps {
  name: string;
  count: number;
  isExpanded: boolean;
  childrenAges?: string[];
  onSave: (value: number, childrenAges: number[]) => void;
}

type TState = [prevCount: number, prevChildrenAges: string[]];

type TAction =
  | { type: 'COUNT'; count: number }
  | { type: 'RESET'; count: number; ages: string[] }
  | { type: 'AGES'; index: number; value: string };

const childrenAgesReducer = (
  [prevCount, prevChildrenAges]: TState,
  action: TAction
): TState => {
  let count = prevCount;
  let childrenAges = [...prevChildrenAges];

  switch (action.type) {
    case 'COUNT':
      if (action.count <= count) {
        childrenAges.pop();
      } else {
        childrenAges.push('');
      }

      count = action.count;

      break;

    case 'RESET':
      count = action.count;
      childrenAges = action.ages;

      break;

    case 'AGES':
      childrenAges[action.index] = action.value;
      break;
  }

  return [count, childrenAges];
};

const ChoiceNumberChildren = (props: IProps) => {
  const { onSave, name, isExpanded } = props;

  const { t } = useTranslation('components');

  const [error, setError] = useState<string>('');
  const [[count, childrenAges], dispatchChildrenAges] = useReducer(
    childrenAgesReducer,
    [0, []]
  );

  useLayoutEffect(() => {
    if (typeof props?.count === 'number' && isExpanded) {
      dispatchChildrenAges({
        type: 'RESET',
        count: props.count,
        ages: props.childrenAges ?? [],
      });
    }
  }, [props.count, props.childrenAges, isExpanded]);

  if (name !== 'children') {
    return null;
  }

  const handleChange = (count: number) => {
    dispatchChildrenAges({ type: 'COUNT', count });
  };

  const handleSave = () => {
    if (!childrenAges.every(Boolean)) {
      setError(t('choice_number.age_of_children_error'));
      return;
    }

    onSave(
      count,
      childrenAges.map((x) => parseInt(x))
    );
  };

  const onAgesChange = (index: number, value: string) => {
    setError('');

    dispatchChildrenAges({ type: 'AGES', index, value });
  };

  return (
    <div className="berg-components-choice-number">
      <div className="berg-components-choice-number-header">
        <p className="berg-components-choice-number-text space">
          {t('choice_number.children')}
        </p>
        <div className="berg-components-choice-number-number-counter">
          <Components.NumberCounter
            counter={count}
            onChange={handleChange}
            maxCounter={99}
          />
        </div>
      </div>
      {count > 0 && (
        <>
          <hr className="berg-components-choice-number-line" />
          <p className="berg-components-choice-number-text--under-3">
            {t('choice_number.children_under_3')}
          </p>
          <p className="berg-components-choice-number-text">
            {t('choice_number.age_of_children')}
          </p>
          {childrenAges.map((_: any, i: number) => (
            <ChildAgeSelect
              key={i}
              index={i}
              onChange={(value) => onAgesChange(i, value)}
              defaultValue={childrenAges[i]}
            />
          ))}
        </>
      )}
      {error && (
        <div className="berg-components-choice-number-error">
          <Icons.AlertAttentionRed />
          {error}
        </div>
      )}
      <hr className="berg-components-choice-number-line" />
      <button
        type="button"
        onClick={handleSave}
        className="berg-components-choice-number-btn"
      >
        {t('choice_number.choose')}
      </button>
    </div>
  );
};

export default ChoiceNumberChildren;
