import React from 'react';
import './SkiSlope.scss';
import Icons from '../../assets/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  skiLength: string;
  skiElevation: string;
  skiDifficulty: string;
}

const SkiSlope = (props: IProps) => {
  const { t } = useTranslation('components');
  const { skiLength, skiElevation, skiDifficulty } = props;
  return (
    <section className="berg-components-ski-slope">
      <div>
        <Icons.LengthSkiSlop />
        <label>
          {t('ski_slope.length')} <strong>{skiLength}</strong>
        </label>
      </div>
      <div>
        <Icons.Mountain />
        <label>
          {t('ski_slope.height')} <strong>{skiElevation}</strong>
        </label>
      </div>
      <div>
        <Icons.Skier />
        <label>
          {t('ski_slope.difficulty')} <strong>{skiDifficulty}</strong>
        </label>
      </div>
    </section>
  );
};

export default SkiSlope;
