import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAccommodationSearchParams,
  IAccommodationState,
  TAccommodationModel,
} from './models';
import { fetchAccommodations } from './operations';

// import ACCOMMODATION_MOCK from './ACCOMMODATION_MOCK.json';
import moment from 'moment';

const initialState = {
  daysCount: 1,
} as IAccommodationState;

const accommodationSlice = createSlice({
  name: 'Accommodations',
  initialState,
  reducers: {
    calculateDaysCount: (state, action) => {
      state.daysCount = moment(action.payload.endDate).diff(
        action.payload.startDate,
        'days'
      );
    },
    saveSearchParams: (
      state,
      action: PayloadAction<IAccommodationSearchParams>
    ) => {
      state.searchParams = action.payload;
    },

    resetCriteria: (state) => {
      state.content = initialState.content;
      state.daysCount = initialState.daysCount;
      state.searchParams = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAccommodations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAccommodations.fulfilled,
      (state, action: PayloadAction<TAccommodationModel>) => {
        state.content = action.payload;

        state.content.sort(
          (prev, curr) => curr.availability - prev.availability
        );

        state.loading = false;
      }
    );
  },
});

export const { calculateDaysCount, saveSearchParams, resetCriteria } =
  accommodationSlice.actions;

export default accommodationSlice.reducer;
