import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Hooks from '.';
import { getBasketTotalQuantity } from '../modules/basket/selectors';

const useCartSteps = (currentStep: number) => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const isBasketNotEmpty = !!Hooks.useAppSelector((s) =>
    getBasketTotalQuantity(s.basket)
  );

  const steps = useMemo(
    () => [
      {
        title: t('cart.steps.step1'),
        onClick:
          currentStep < 3 && currentStep !== 0
            ? () => navigate('/cart')
            : undefined,
      },
      {
        title: t('cart.steps.step2'),
        onClick:
          currentStep < 3 && currentStep !== 1 && isBasketNotEmpty
            ? () => navigate('/cart/order-data')
            : undefined,
      },
      {
        title: t('cart.steps.step3'),
      },
      {
        title: t('cart.steps.step4'),
      },
      {
        title: t('cart.steps.step5'),
      },
    ],
    [t, currentStep, isBasketNotEmpty, navigate]
  );

  return { steps, currentStep };
};

export default useCartSteps;
