import { useTranslation } from 'react-i18next';
import { IOrder } from '../../../modules/order/models/details';
import './OrderSummaryFooter.scss';
interface IProps {
  orderDetails: IOrder;
}
const OrderSummaryFooter = ({ orderDetails }: IProps) => {
  const { t } = useTranslation('components');

  const onlinePrice = Number(orderDetails?.onlinePayment.grossAmount);

  return (
    <div>
      <div className="order-summary-footer">
        <div className="order-summary-footer__item-header--bold">
          {t('order_summary_header.amount_label')}
        </div>
        <div className="order-summary-footer__item-description--bold">
          {onlinePrice} {orderDetails?.onlinePayment.currency}
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryFooter;
