import { IProductType } from '../common/models/productModels';
import { ISkiPass, ISkiPassStateModel } from './models/skiPass';

export const getSkiPassTypes = (state: ISkiPassStateModel): IProductType[] =>
  state.skiPassTypes;

export const getSkiPasses = (state: ISkiPassStateModel): ISkiPass[] =>
  state.skiPasses;

export const getValidityDate = (state: ISkiPassStateModel): string =>
  state.validityDate;
