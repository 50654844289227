import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import useDialog from './useDialog';
import useOutsideClick from './useOutsideClick';
import usePriceDisplay from './usePriceDisplay';
import useQueryParams from './useQueryParams';

const Hooks = {
  useOutsideClick,
  usePriceDisplay,
  useAppDispatch,
  useAppSelector,
  useDialog,
  useQueryParams
};

export default Hooks;
