import Icons from '../../assets/icons';
import './ValidationMessage.scss';

interface IProps {
  content?: string | null;
  children?: JSX.Element;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'primary-dark'
    | 'gray-400'
    | 'white'
    | 'white-border';
}

const ValidationMessage = (props: IProps) => {
  const { content, children, color } = props;

  const colorClass = `berg-validation-message__${color ?? 'error'}`;

  if (['null', 'undefined'].includes(typeof content) || content === '') {
    return children ?? null;
  }

  return (
    <div className={['berg-validation-message', colorClass].join(' ')}>
      <div className="berg-validation-message__body">
        {children ?? <Icons.InfoCircle />} {content}
      </div>
    </div>
  );
};

export default ValidationMessage;
