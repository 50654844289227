import { FC, SVGProps, useEffect, useMemo, useRef, useState } from 'react';
import ReactDropdown, { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import './Dropdown.scss';

export interface IDropdownOption {
  value: string;
  label: string;
}

interface IProps {
  options?: IDropdownOption[];
  defaultValue?: string;
  shouldReset?: boolean;
  name: string;
  onChange?: (value: string) => void;
  Icon?: FC<SVGProps<SVGSVGElement>>;
}

const Dropdown = (props: IProps) => {
  const { t } = useTranslation('common');

  const defaultOptions = [
    {
      label: t('choose'),
      value: '',
    },
  ];

  const {
    options = defaultOptions,
    name,
    Icon,
    shouldReset,
    defaultValue,
    onChange,
  } = props;

  const [option, setOption] = useState<IDropdownOption>(defaultOptions[0]);
  const isDefaultOptionSet = useRef<boolean>(false);

  const isDisabled = !props.options;

  const onDropdownChange = (e: Option) => {
    setOption(options.find((o) => o.value == e.value) ?? options[0]);
    onChange?.(e.value);
  };

  useEffect(() => {
    if (shouldReset) {
      setOption(defaultOptions[0]);
    }
  }, [shouldReset]);

  useEffect(() => {
    const defaultOption = options?.find(
      (option) => option.value === defaultValue
    );

    if (defaultOption && !isDefaultOptionSet.current) {
      setOption(defaultOption);
      onChange?.(defaultOption.value);

      isDefaultOptionSet.current = true;
    }
  }, [defaultValue, onChange, options]);

  const placeholderClassName = useMemo(() => {
    const className = ['berg-components-dropdown__placeholder'];

    if (Icon) {
      className.push('berg-components-dropdown__placeholder--with-icon');
    }

    return className.join(' ');
  }, [Icon]);

  const wrapperClassName = `berg-components-dropdown-wrapper${
    isDisabled ? ' berg-components-dropdown-wrapper--disabled' : ''
  }`;

  return (
    <div className={wrapperClassName}>
      <ReactDropdown
        disabled={isDisabled}
        options={options}
        value={option}
        onChange={onDropdownChange}
        className="berg-components-dropdown"
        controlClassName="berg-components-dropdown__control"
        placeholderClassName={placeholderClassName}
        arrowClosed={
          <span className="berg-components-dropdown__arrow-closed" />
        }
        arrowOpen={<span className="berg-components-dropdown__arrow-open" />}
      />
      {!!Icon && (
        <Icon className="berg-components-dropdown__placeholder-icon" />
      )}
      <input type="text" name={name} value={option.value} readOnly />
    </div>
  );
};

export default Dropdown;
