import React from 'react';
import './AboutRegionAdditionalInformation.scss';
import Icons from '../../assets/icons';

import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen?: boolean;
  isEmpty?: boolean;
  open?: string;
  location?: string;
  qr?: JSX.Element;
}

const AboutRegionAdditionalInformation = (props: IProps) => {
  const { isOpen, open, location, qr, isEmpty } = props;
  const { t } = useTranslation('components');
  return (
    <section>
      <hr className="berg-components-about-region-additional-information-line" />
      <div className="berg-components-about-region-additional-information">
        <div className="berg-components-about-region-additional-information-items-open">
          {isOpen ? (
            <>
              <Icons.Clock />
              <label>
                {t('about_region_additional_information.open')}
                <strong>{open}</strong>
              </label>
            </>
          ) : (
            <>
              {isEmpty ? (
                ''
              ) : (
                <>
                  <Icons.Clock />
                  <label>
                    <strong>
                      {t('about_region_additional_information.close')}
                    </strong>
                  </label>
                </>
              )}
            </>
          )}
        </div>
        <div className="berg-components-about-region-additional-information-items-localization">
          <Icons.LocationPin />
          <label>
            <strong>{location}</strong>
          </label>
        </div>
        <div className="berg-components-about-region-additional-information-items-qr">
          <div>
            <Icons.Smartphone />
            <label>
              <strong>
                {t('about_region_additional_information.take_picture')}
              </strong>
            </label>
          </div>
          {qr}
        </div>
      </div>
    </section>
  );
};

export default AboutRegionAdditionalInformation;
